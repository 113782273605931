import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from '../Typography';
import { AnimatePresence, motion } from 'framer-motion';
import WorkDetail from '../../pages/WorkDetail';
import { GnbContext } from '../Mobile';
import Footer from '../Footer';

export default function WorkEntry() {
    const transitionRef = useRef();
    const [isEnded, setEnded] = useState(false);
    const [readyShowDetail, setReadyShowDetail] = useState(false);
    const { project } = useParams();
    const title = 'OUR WORK'.split('');
    const projectListLen = document.querySelectorAll('.thumb-list').length;
    useEffect(() => {
        if (project) {
            // 1. display none -> block
            transitionRef.current.style.display = 'block';
            // 2. opacity 0 -> 1
            setTimeout(() => {
                transitionRef.current.style.opacity = 1;
            }, 100);
            // 3. scroll to top
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300);
            setTimeout(() => {
                setReadyShowDetail(true);
            }, 350);
        } else {
            setReadyShowDetail(false);
            transitionRef.current.style.display = 'none';
            transitionRef.current.style.opacity = 0;
        }
    }, [project]);

    return (
        <>
            <section className={`WorkEntry`} style={{overflow: project ? "hidden" : "initial"}}>
                <div className="transition" ref={transitionRef}></div>
                <div className="sticky-container">
                    <div className="sticky">
                        {isEnded && (
                            <div className={`msg-wrap`}>
                                <div className={`small-msg`}>
                                    <div className="flex-column text-en">
                                        <Typography txt="let me show you" />
                                        <Typography delay={1} txt="our work " />
                                        <Typography delay={2} txt="from now on!" />
                                    </div>
                                    <div className="text-ko flex-column">
                                        <Typography delay={3} className="right" txt="준비됐나요?" />
                                        <Typography
                                            delay={4}
                                            txt="우리의 특별하고 혁신적인 작업물을"
                                        />
                                        <Typography
                                            delay={5}
                                            className="last"
                                            txt=" 보여드릴게요!"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={`title-box`}>
                            <h2 className="title">
                                <motion.div
                                    className="word"
                                    viewport={{ once: true }}
                                    whileInView="visible"
                                    initial="hidden">
                                    {title.map((el, idx) => {
                                        return el == ' ' ? (
                                            <br key={idx} />
                                        ) : (
                                            <motion.span
                                                key={idx}
                                                variants={{
                                                    hidden: {
                                                        opacity: 0,
                                                        y: '-100%',
                                                        transition: {
                                                            duration: 0.4,
                                                            delay: 1
                                                        }
                                                    },
                                                    visible: {
                                                        opacity: 1,
                                                        y: '0%',
                                                        transition: {
                                                            duration: 0.4,
                                                            delay: idx * 0.025,
                                                            ease: 'linear'
                                                        }
                                                    }
                                                }}
                                                onAnimationComplete={() => {
                                                    if (idx == title.length - 1) {
                                                        setTimeout(() => {
                                                            setEnded(true);
                                                        }, 100);
                                                    }
                                                }}>
                                                {el}
                                            </motion.span>
                                        );
                                    })}
                                </motion.div>
                                <div className={`project-lenght ${isEnded && 'show'}`}>
                                    <span>{projectListLen}</span>
                                </div>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="scroll">
                    {
                        <div className={isEnded ? 'end thumb-box' : 'thumb-box'}>
                            <div className="thumb-box-container">
                                <ul className={`thumb-wrap bottom-wrap`}>
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_shinsegae_award.webp'
                                        }
                                        name={'SHINSEGAE SHOPPING APP'}
                                        nameEn={'Shinsegae live shopping'}
                                        project={'shinsegae'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_archiveat_award.webp'
                                        }
                                        name={'ARCHIVEAT'}
                                        nameEn={'Public Kitchen'}
                                        project={'archiveat'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_metapassport.webp'
                                        }
                                        name={'META PASSPORT'}
                                        nameEn={'SM Brand Marketing'}
                                        project={'metaPassport'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_kiarental.webp'
                                        }
                                        name={'KIA Rental'}
                                        nameEn={'KIA Motors Corporation'}
                                        project={'kiaRental'}
                                    />

                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_ddb_award.webp'
                                        }
                                        name={'Daldabang'}
                                        nameEn={'Yuhan Kimberly'}
                                        project={'ddb'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_bentley_award.webp'
                                        }
                                        name={'Bentley Membership'}
                                        nameEn={'Bentley Seoul'}
                                        project={'bentley'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_ekolon.webp'
                                        }
                                        name={'e-kolon membership'}
                                        nameEn={'Kolon Industries'}
                                        project={'ekolon'}
                                    />

                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_lexus_award.webp'
                                        }
                                        name={'LEXUS LOUNGE'}
                                        nameEn={'LEXUS Korea'}
                                        project={'lexus'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_picks.webp'
                                        }
                                        name={'picks'}
                                        nameEn={'Weathernews'}
                                        project={'picks'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_stylerpick.webp'
                                        }
                                        name={'주부생활'}
                                        nameEn={'THE BOOK COMPANY'}
                                        project={'stylerpick'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_toyota.webp'
                                        }
                                        name={'Showroom & Workshop'}
                                        nameEn={'toyota'}
                                        project={'toyota'}
                                    />
                                    <ThumbList
                                        transitionRef={transitionRef}
                                        img={
                                            process.env.PUBLIC_URL +
                                            '/image/img_work_thumb_wego.webp'
                                        }
                                        name={'Tango&go'}
                                        nameEn={'wego'}
                                        project={'wego'}
                                    />
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </section>
            {project && readyShowDetail && (
                <>
                    <AnimatePresence>
                        <motion.div
                            className="WorkDetail"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 0.2
                                }
                            }}
                            exit={{ opacity: 0 }}>
                            <WorkDetail project={project} />
                        </motion.div>
                    </AnimatePresence>
                </>
            )}
            <Footer />
        </>
    );
}

function ThumbList(props) {
    return (
        <motion.li
            initial="hidden"
            whileInView='visible'
            viewport={{ once: true }}
            className={`thumb-list ${props.class ? props.class : ''}`}>
            <Link to={`/work/${props.project}`}>
                <motion.div
                    key="animation-on-state"
                    variants={{
                        initial: {
                            opacity: 1,
                            y: '0%',
                            transition: {
                                duration: 0
                            }
                        },
                        hidden: { 
                            opacity: 0,
                            y: '100%',
                            transition: {
                                duration: 0
                            }
                        },
                        visible: (custom = 0) => ({
                            opacity: 1,
                            y: '0%',
                            transition: {
                                duration: 0.8,
                            }
                        })
                    }}>
                    <div>
                        <div className="img-wrap">
                            <img src={process.env.PUBLIC_URL + props.img} alt="" />
                        </div>

                        <div className="desc-wrap">
                            <h3 className="work-name">{props.name}</h3>
                            <h4 className="work-name-en">{props.nameEn}</h4>
                        </div>
                    </div>
                </motion.div>
            </Link>
        </motion.li>
    );
}
