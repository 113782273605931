import { GnbContext } from '../Pc';
import { useEffect, useContext, useRef } from 'react';
import { motion, useInView } from 'framer-motion';

export default function Beautyselfpick() {
    const aniRef = useRef();
    const isInView = useInView(aniRef, { once: true });
    const gnbContext = useContext(GnbContext);
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Beautyselfpick">
            <div className="work-detail-wrap work-detail-container" data-hash="beautyselfpick">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">AmorePacific</h2>
                            <h4 className="project-name">Beauty Selfpick</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    뷰티 셀픽은 여행객이 오프라인 면세점에 방문했을 때, 본인의
                                    스마트폰에서 상품을 스스로 주문할 수 있는 서비스입니다. 다양한
                                    국적의 사용자가 방문하는 면세점의 특성상 외국인 고객의 서비스
                                    이용 측면을 고려하였습니다.
                                    <br />
                                    <br />
                                    면세점 이용객들은 매장 내 혹은 여행사에서 제공하는 QR코드 스캔
                                    후 모바일 웹에 접속하여 해당 매장의 제품을 선 주문할 수
                                    있습니다. 재고 확인이 가능하여 해당 매장의 품절 여부를 확인할 수
                                    있고 각국 언어별로 제공되는 상세 설명들과 함께 결제할 금액을
                                    당일 환율로 확인할 수 있습니다.
                                    <br />
                                    <br />
                                    직원들은 주문 내용을 앱에서 미리 확인하여 준비할 수 있습니다.
                                    매장에 단체 관광객이 방문하여 동시에 응대가 어려울 경우 미리
                                    상품을 준비해두는 등 빠르게 응대를 준비할 수 있습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <div className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.ul
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}>
                                    <motion.li variants={variants}>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_beautyselfpick_logo.svg'
                                            }
                                            alt="뷰티셀픽 로고"
                                        />
                                    </motion.li>
                                    <motion.li variants={variants}>
                                        <div className="video-rectangle reactive-rectangle">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_bsp_rectangle.svg'
                                                }
                                            />
                                        </div>
                                        <video
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/video/aweb_beautyselfpick_01.mov'
                                            }
                                            muted
                                            autoPlay
                                            playsInline
                                            poster={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_beautyselfpick_section01_01_mobile.webp'
                                            }></video>
                                        {/* <img src={process.env.PUBLIC_URL +'/site/res/image/img_beautyselfpick_section01_01_mobile.webp'} alt="뷰티셀픽 이미지" />  */}
                                    </motion.li>
                                </motion.ul>
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_beautyselfpick_section01_01.webp'
                                    }
                                    alt="뷰티셀픽 이미지"
                                />
                            </motion.div>
                        </div>

                        <div
                            className="work-detail-information-container work-detail-information-container-02"
                            ref={aniRef}>
                            <div
                                className={`work-detail-information-img work-detail-information-img-01 fade-up ${
                                    isInView && 'show'
                                }`}
                                data-delay="1">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="1">
                                        <motion.img
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    y: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 1.8
                                                        },
                                                        y: {
                                                            duration: 1.2
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.3,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, y: '50%' }
                                            }}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_beautyselfpick_section02_01.webp'
                                            }
                                            alt="뷰티셀픽 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.img
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    y: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 1.8
                                                        },
                                                        y: {
                                                            duration: 1.2
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.5,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, y: '50%' }
                                            }}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_beautyselfpick_section02_02.webp'
                                            }
                                            alt="뷰티셀픽 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="5">
                                        <motion.img
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    y: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 1.8
                                                        },
                                                        y: {
                                                            duration: 1.2
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.7,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, y: '50%' }
                                            }}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_beautyselfpick_section02_03.webp'
                                            }
                                            alt="뷰티셀픽 이미지"
                                        />
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="6">
                                        <motion.img
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    y: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 1.8
                                                        },
                                                        y: {
                                                            duration: 1.2
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.9,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, y: '50%' }
                                            }}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_beautyselfpick_section02_04.webp'
                                            }
                                            alt="뷰티셀픽 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_beautyselfpick_section02_05.webp'
                                    }
                                    alt="뷰티셀픽 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up"
                                data-delay="5">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_beautyselfpick_section02_06.webp'
                                    }
                                    alt="뷰티셀픽 이미지"
                                />
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.div variants={variants} className="animation-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_beautyselfpick_section03_01.webp'
                                        }
                                        alt="뷰티셀픽 이미지"
                                    />
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                data-delay="10">
                                <motion.div variants={variants} className="animation-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_beautyselfpick_section03_02.webp'
                                        }
                                        alt="뷰티셀픽 이미지"
                                    />
                                </motion.div>
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.div variants={variants} className="animation-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_beautyselfpick_section03_03.webp'
                                        }
                                        alt="뷰티셀픽 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_beautyselfpick_logo.svg'
                                    }
                                    alt="뷰티셀픽 로고"
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
