import { animate, motion, stagger, useInView, useScroll } from 'framer-motion';
import { useEffect, useRef, useContext } from 'react';
import { GnbContext } from '../Pc';
import RandomTypo from '../RandomTypo';
export default function SolutionSectionDestination() {
    const sectionRef = useRef();
    const isInView = useInView(sectionRef);
    const gnbContext = useContext(GnbContext);
    const sectionInView = useInView(sectionRef, { amount: 1 });

    useEffect(() => {
        if (sectionRef.current.getBoundingClientRect().top <= 0) {
            gnbContext.actions.setGnbColor('white');
        } else {
            gnbContext.actions.setGnbColor('black');
        }
    }, [sectionInView]);

    return (
        <div className={`SolutionSectionDestination${isInView ? ' black' : ''}`} ref={sectionRef}>
            <div className={`text-container`}>
                <RandomTypo text="HERE IS DESTINATION" onComplete={() => {
                    animate(
                        '.fade-in',
                        { opacity: 1 },
                        {
                            duration: 0.3,
                            delay: stagger(0.3, { startDelay: 0 })
                        }
                    ).then(() => {
                        animate(
                            '.tag',
                            {
                                y: ['500%', '0%']
                            },
                            {
                                type: 'spring',
                                stiffness: 150,
                                delay: stagger(0.1, { from: 'center' })
                            }
                        );
                        animate(
                            '.tag span',
                            {
                                opacity: [0, 1]
                            },
                            {
                                duration: 0.2
                            }
                        );
                    });
                }}/>
                <div className="msg-en fade-in" data-order="1">
                    Our solution is right here,
                    <br />
                    So go for it!
                </div>
                <div className="msg-ko fade-in" data-order="2">
                    <span className="right">이 지속 가능한 솔루션들이 여러분의 비전을</span>
                    <span>
                        실현할 수 있도록 길잡이가 되어 드릴게요.
                        <br />
                        앱노트는 더 나은 세상을 만들어가는 여러분들과
                    </span>
                    <span className="right">함께할 날을 기다리고 있습니다.</span>
                </div>

                <div className="tag handly">
                    <span>HANDLY</span>
                </div>
                <div className="tag popee">
                    <span>POPEE</span>
                </div>
                <div className="tag mentor">
                    <span>MENTOR</span>
                </div>
            </div>
        </div>
    );
}
