import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';
import { debounce } from 'lodash';

export const MatterJs = ({background="#fff", gravity = 0}) => {
    const boxRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        let Engine = Matter.Engine;
        let Render = Matter.Render;
        let World = Matter.World;
        let Bodies = Matter.Bodies;
        let Body = Matter.Body;
    
        let engine = Engine.create();
    
        let render = Render.create({
            engine: engine,
            canvas: canvasRef.current,
            options: {
                background: background,
                width: window.innerWidth,
                height: window.innerHeight,
                wireframes: false
            }
        });
    
        setTimeout(() => {
            if (gravity == 0) {
                engine.gravity.scale = 0;
            }
            const staticTop = Bodies.rectangle(
                window.innerWidth / 2,
                getHeight(-200),
                (window.innerWidth*1.5) - 10,
                5,
                {
                    isStatic: true,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
            World.add(engine.world, [
                staticTop,
            ]);
        }, 1000);
    
        const staticBottom = Bodies.rectangle(
            window.innerWidth * 1.5 / 2,
            window.innerHeight - 5,
            (window.innerWidth*1.5) - 10,
            5,
            {
                isStatic: true,
                render: {
                    fillStyle: 'none'
                }
            }
        );
        const staticRight = Bodies.rectangle(
            (window.innerWidth * 1.5) - 5,
            window.innerHeight / 2,
            5,
            window.innerHeight*2,
            {
                isStatic: true,
                render: {
                    fillStyle: 'none'
                }
            }
        );
        const staticLeft = Bodies.rectangle(
            (window.innerWidth/2) * -1,
            window.innerHeight / 2,
            5,
            window.innerHeight*2,
            {
                isStatic: true,
                render: {
                    fillStyle: 'none'
                }
            }
        );
    
        function getWidth(width) {
            return window.innerWidth * (width / 390);
        }
        function getHeight(height) {
            return window.innerHeight * (height / 844);
        }
    
        // x, y, radius
        const body1 = Bodies.circle(getWidth(300), getHeight(-208), getWidth(400/2), {
            restitution: 1,
            friction: 0.5,
            render: {
                strokeStyle: '#FFFFFF',
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/mobile/img_symbol_01.webp',
                    xScale: window.innerWidth / 390,
                    yScale: window.innerWidth / 390
                }
            },
        });
        // x, y, sides, radius
        const body2 = Bodies.polygon(getWidth(0), getHeight(147), 3.3, getWidth(415/2), {
            restitution: 0.7,
            friction: 0.5,
            render: {
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/mobile/img_symbol_02.webp',
                    xScale: window.innerWidth / 390,
                    yScale: window.innerWidth / 390
                }
                
            },
        });
    
        // x, y, width, height, slope
        const body3 = Bodies.trapezoid(getWidth(34), getHeight(282), getWidth(344), getWidth(415), 1, {
            restitution: 0.7,
            friction: 0.5,
            render: {
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/mobile/img_symbol_03.webp',
                    xScale: window.innerWidth / 390,
                    yScale: window.innerWidth / 390
                }
            }
        });
    
        // x, y, width, height
        const body4 = Bodies.rectangle(getWidth(152), getHeight(183), getWidth(400), getWidth(318), {
            restitution: 0.7,
            render: {
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/mobile/img_symbol_04.webp',
                    xScale: window.innerWidth / 390,
                    yScale: window.innerWidth / 390
                }
            }
        });
    
        Body.rotate(body2, 0);
        Body.rotate(body3, 1.5);
    
        World.add(engine.world, [
            // staticTop,
            staticBottom,
            staticRight,
            staticLeft,
            body1,
            body2,
            body3,
            body4
        ]);
    
        // Engine.run(engine);
        Matter.Runner.run(engine);
        Render.run(render);
        window.addEventListener('resize', debounce(() => {
            canvasRef.width = window.innerWidth;
            canvasRef.height = window.innerHeight;
            render.bounds.max.x = window.innerWidth;
            render.bounds.max.y = window.innerHeight;
            render.options.width = window.innerWidth;
            render.options.height = window.innerHeight;
            render.canvas.width = window.innerWidth;
            render.canvas.height = window.innerHeight;
        }, 250));
    },[]);

    return (
        <div className="MatterJs" ref={boxRef}>
            <canvas id="canvas" ref={canvasRef} />
        </div>
    );
};
