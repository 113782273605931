import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';
export default function KiaRental() {
    
    const wdp = workDetailPath();
    const wp = wdp + 'kiarental/';

    return (
        <div className="work-detail-wrap kiarental">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">KIA Motors Corporation</h2>
                        <h4 className="project-name">KIA Rental</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                기아자동차 렌터카 대여 고객을 위한 웹사이트와 기아렌터카 영업사원을
                                위한 안드로이드 전용 태블릿 어플리케이션입니다.
                                <br />
                                <br />
                                기아렌터카 웹사이트는 기아자동차의 장기 렌터카 견적을 손쉽게
                                커스텀하여 받아보고, 렌터카 상담 및 계약까지 편리하게 진행할 수 있는
                                서비스입니다. 차량 출고 후에도 차량 정보 및 여러 다이렉트 서비스,
                                계약 기간 만료 시 차량 반납까지 고객이 차량의 모든 라이프 사이클을
                                관리할 수 있도록 차량 관리 기능을 제공합니다.
                                <br />
                                <br />
                                영업사원용 태블릿 어플리케이션에서는 영업사원들의 고객 및 견적, 계약
                                관리 등의 전반적인 영업 활동 업무가 쉽고 편리해질 수 있도록
                                구성되었습니다. 오프라인 뿐만 아니라 온라인 웹사이트를 통한 고객의
                                견적을 받아보고, 상담을 통한 계약, 추후 차량 반납까지의 모든
                                프로세스를 태블릿에서 진행할 수 있습니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_01_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_01_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_02_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_02_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-03">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-04">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_04_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-05">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_05_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_05_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_05_03.png'} />
                    </Fadeup>
                    <Fadeup className="img-04">
                        <img src={wp + 'img_05_04.png'} />
                    </Fadeup>
                </section>

                <section className="section-06">
                    <div className="marquee-container">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_06_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_05.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_06_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_05.png'} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-container reverse">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_06_slide_06.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_07.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_08.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_09.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_10.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_06_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_06_slide_05.png'} />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + 'img_06_01.png'} />
                        </Fadeup>
                        <Fadeup className="img-02">
                            <img src={wp + 'img_06_02.png'} />
                        </Fadeup>
                        <Fadeup className="img-03">
                            <img src={wp + 'img_06_03.png'} />
                        </Fadeup>
                        <Fadeup className="img-04">
                            <img src={wp + 'img_06_04.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-07">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_07_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_07_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_07_03.png'} />
                    </Fadeup>
                    <div className="img-04">
                        <img src={wp + 'img_07_04.png'} />
                    </div>
                </section>

                <section className="section-08">
                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + 'img_08_01.png'} />
                        </Fadeup>
                        <Fadeup className="img-02">
                            <img src={wp + 'img_08_02.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-09">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_09_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-10">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_10_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-11">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_11_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_11_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_11_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-12">
                    <img src={wp + "img_12_01.png"} />
                </section> 

                <section className="section-13">
                    <Fadeup className="img-01">
                        <img src={wp + "img_13_01.png"} />
                    </Fadeup>
                </section> 

                <section className="section-14">
                    <img src={wp + "img_14_01.png"} />
                </section> 

                <section className="section-15">
                    <Fadeup className="img-01">
                        <img src={wp + "img_15_01.png"} />
                    </Fadeup>
                </section> 

                <section className="section-16">
                    <img src={wp + "img_16_01.png"} />
                </section> 

                <section className="section-17">
                    <Fadeup className="img-01">
                        <img src={wp + "img_17_01.png"} />
                    </Fadeup>
                </section> 

                <section className="section-18">
                    <Fadeup className="img-01">
                        <img src={wp + "img_18_01.png"} />
                    </Fadeup>
                </section> 
            </div>
        </div>
    );
}
