import { motion } from "framer-motion";
import LinkWithTransition from "./LinkWithTransition";
export default function WorkList(props) {

    return (
        <motion.li className="WorkList" initial="hidden" whileInView="visible" viewport={{ once: true }}
        variants={{
                    hidden: { 
                        opacity: 0,
                        y: "50%",
                    },
                    visible: {
                        opacity: 1,
                        y: "0%",
                        transition: {
                            duration: 1,
                        }
                    },
                }}>
            <LinkWithTransition to={props.to}>
                <div className="thumb-nail">
                    <img src={ props.img } />
                </div>

                <div className="work-text">
                    <h4>{props.title}</h4>
                    <span>{props.sub}</span>
                </div>
            </LinkWithTransition>
        </motion.li>
    )
}