import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { GnbContext } from '../Pc';
import { useEffect, useContext, useRef } from 'react';
import { motion, useInView } from 'framer-motion';

export default function Picks() {
    const aniRef = useRef();
    const gnbContext = useContext(GnbContext);
    const isInView = useInView(aniRef, { once: true, amount: 0.7 });
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Picks">
            <div className="work-detail-wrap" data-hash="picks">
                <div id="workDetailContainer" className="work-detail-container">
                    <div className="project-cover">
                        <div className="cover-img-wrap"></div>
                        <div className="cover-text-wrap">
                            <div className="container">
                                <h2 className="project-type">Weathernews</h2>
                                <h4 className="project-name">Picks</h4>
                                <ul className="project-team">
                                    <li className="team-tag">design</li>
                                    <li className="team-tag">Project Management</li>
                                    <li className="team-tag">development</li>
                                </ul>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="project-desc">
                                    <motion.p variants={variants}>
                                        픽스는 라이프 콘텐츠를 큐레이션 하여 제공해 주는
                                        서비스입니다.
                                        <br />
                                        <br />
                                        삼성 갤럭시 스마트폰에 탑재된 날씨 위젯 내에서 모바일 웹을
                                        통해 생활 정보 콘텐츠를 공급하고 있으며 글로벌 버전으로
                                        리뉴얼 되었습니다.
                                        <br />
                                        <br />
                                        리뉴얼 과정에서 콘텐츠 제공 방식과 서비스 안정성 향상에 큰
                                        변화가 있었습니다. 앱노트의 CMS(Content Management System)
                                        솔루션 경험과 노하우를 기반으로 지난 2년간 누적되었던
                                        콘텐츠들을 오늘의 픽, 해시 태그, 추천 콘텐츠 등 다양한
                                        방식으로 노출 방법에 변화를 주었습니다.
                                        <br />
                                        <br />
                                        또한 2019년부터 운영된 초기 버전의 인프라를 상회함에 따라
                                        배치 서버를 도입하였습니다. 접속량이 증가함에 따라 안정성과
                                        속도 개선을 위해 심야 시간이나 접속률이 낮은 시간에 콘텐츠를
                                        수집/분류하는 작업을 진행하였고 서비스의 안정성과 속도가
                                        개선되었습니다.
                                        <br />
                                        <br />
                                        기존 국내 서비스로 기획되었던 픽스는 국내 법령에 맞춰 구현이
                                        되었으나, 글로벌 서비스로 리뉴얼 됨에 따라 EU의 GDPR 및 미국
                                        내 프라이버시 법령에 따른 서비스의 변화가 예상됩니다. 글로벌
                                        서비스 특성에 맞는 유연한 대처로 국/내외 동일한 수준의
                                        서비스를 제공하고자 합니다.
                                    </motion.p>
                                </motion.div>
                            </div>
                        </div>
                    </div>

                    <div className="work-detail-contents dark">
                        <section className="work-detail-intro"></section>

                        <section className="work-detail-information">
                            <div className="work-detail-information-container work-detail-information-container-01">
                                <div className="work-detail-information-img-container" ref={aniRef}>
                                    <div
                                        className={`work-detail-information-img work-detail-information-img-01 reactive-rectangle fade-up picks-ani ${
                                            isInView && 'on'
                                        }`}>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_picks_section01_01.webp'
                                            }
                                            alt="Picks 이미지"
                                        />
                                    </div>
                                    <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="work-detail-information-img work-detail-information-img-02 fade-up"
                                        data-delay="3">
                                        <motion.div
                                            variants={variants}
                                            className="animation-container">
                                            <div className="picks-video">
                                                <video
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/site/res/image/video/aweb_picks_02.mp4'
                                                    }
                                                    type="video/mp4"
                                                    loop
                                                    autoPlay
                                                    muted
                                                    playsInline
                                                    poster={
                                                        process.env.PUBLIC_URL +
                                                        '/site/res/image/img_picks_section01_02_mobile.webp'
                                                    }></video>
                                                {/* <img src={process.env.PUBLIC_URL +'/site/res/image/img_picks_section01_02_mobile.webp'} alt="Picks 이미지" /> */}
                                            </div>
                                            <div className="picks-title">도심 속 여행떠나기</div>
                                        </motion.div>
                                    </motion.div>
                                    <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="work-detail-information-img work-detail-information-img-03 fade-up"
                                        data-delay="5">
                                        <motion.img
                                            variants={variants}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_picks_section01_02.webp'
                                            }
                                            alt="Picks 이미지"
                                        />
                                    </motion.div>
                                </div>
                            </div>

                            <div className="work-detail-information-container work-detail-information-container-02">
                                <div className="work-detail-information-img work-detail-information-img-01 fade-up">
                                    <motion.ul
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}>
                                        <motion.li variants={variants}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section02_01.svg'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                        <motion.li variants={variants}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section02_02.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                    </motion.ul>
                                </div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section02_03.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                            </div>

                            <div className="work-detail-information-container work-detail-information-container-03">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-00 for-mobile fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section03_04.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                                <div className="work-detail-information-img work-detail-information-img-01">
                                    <ul>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_01.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up"
                                            data-delay="2">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_02.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up"
                                            data-delay="2">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_03.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                    </ul>
                                </div>
                                <div className="work-detail-information-img work-detail-information-img-02">
                                    <ul>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up"
                                            data-delay="3">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_04.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up"
                                            data-delay="8">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_05.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>

                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up picks-video"
                                            data-delay="6">
                                            <motion.video
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/video/aweb_picks_01.mp4'
                                                }
                                                autoPlay
                                                muted
                                                loop
                                                playsInline
                                                poster={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_06_mobile.webp'
                                                }></motion.video>
                                        </motion.li>
                                        <motion.li
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            className="fade-up"
                                            data-delay="5">
                                            <motion.img
                                                variants={variants}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section03_06.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </motion.li>
                                    </ul>
                                </div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section03_07.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-04 for-mobile fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section03_05.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-05 for-mobile fade-up">
                                    <motion.video
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/video/aweb_picks_01.mp4'
                                        }
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        poster={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section03_06_mobile.webp'
                                        }></motion.video>

                                    {/* <imgsrc={process.env.PUBLIC_URL +'/site/res/image/img_picks_section03_06_mobile.webp'} alt="Picks 이미지" /> */}
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-06 for-mobile fade-up">
                                    <motion.img
                                        variants={variants}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section03_06.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                            </div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-container work-detail-information-container-04">
                                <motion.div
                                    variants={variants}
                                    className="picks-section-04-title fade-up"
                                    data-delay="3">
                                    <div className="animation-container"># 오피스</div>
                                </motion.div>

                                <div
                                    className="work-detail-information-img work-detail-information-img-01 fade-up"
                                    data-delay="5">
                                    <Swiper
                                        loop={true}
                                        autoplay={{ delay: 1, disableOnInteraction: false }}
                                        speed={2000}
                                        modules={[Autoplay]}
                                        slidesPerView={'auto'}
                                        freeMode={true}
                                        centeredSlides={false}>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section04_01.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section04_02.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section04_03.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section04_04.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section04_05.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_picks_section04_06.webp'
                                                }
                                                alt="Picks 이미지"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </motion.div>

                            <div className="work-detail-information-container work-detail-information-container-05">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-bg reactive-rectangle fade-up">
                                    <motion.span variants={variants}></motion.span>
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-01 fade-up"
                                    data-delay="3">
                                    <motion.img
                                        variants={{
                                            visible: {
                                                opacity: 1,
                                                y: '0%',
                                                transition: {
                                                    opacity: {
                                                        duration: 1.8
                                                    },
                                                    y: {
                                                        duration: 1.2
                                                    },
                                                    duration: 1.2,
                                                    delay: 0.5,
                                                    ease: 'linear'
                                                }
                                            },
                                            hidden: { opacity: 0, y: '50%' }
                                        }}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section05_01.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="work-detail-information-img work-detail-information-img-02 fade-up"
                                    data-delay="3">
                                    <motion.img
                                        variants={{
                                            visible: {
                                                opacity: 1,
                                                y: '0%',
                                                transition: {
                                                    opacity: {
                                                        duration: 1.8
                                                    },
                                                    y: {
                                                        duration: 1.2
                                                    },
                                                    duration: 1.2,
                                                    delay: 0.5,
                                                    ease: 'linear'
                                                }
                                            },
                                            hidden: { opacity: 0, y: '50%' }
                                        }}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_picks_section05_02.webp'
                                        }
                                        alt="Picks 이미지"
                                    />
                                </motion.div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}
