import Typography from '../Typography';
import SolutionIntroduceIntro from './SolutionIntroduceIntro';
import SolutionInfoIntro from './SolutionInfoIntro';
import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView, useMotionValueEvent, useScroll } from 'framer-motion';
import "pathseg";
import Matter, { Common, Svg, Vertices } from 'matter-js';
import { debounce } from 'lodash';
export default function SolutionSectionPopee() {
    const unionVariants = {
        hidden: { 
            y: "100%",
            transition: {
                duration: 0.3,
                delay: 0
            }
        },
        visible: { 
            y: "0%",
            transition: {
                duration: 0.8, 
                delay: 0.2,
                ease: "easeInOut"
            },
        }
    };
    const textArr = ['Paperless'.split(''), 'Pop-up'.split(''), 'Solution'.split('')];
    const sectionRef = useRef();
    const canvasRef = useRef();
    const section03Ref = useRef();

    const matterRef01 = useRef();
    const matterRef02 = useRef();
    const matterRef03 = useRef();

    const [isScroll, setIsScroll] = useState(false);
    const { scrollYProgress } = useScroll({
        target: sectionRef
    });
    const isInViewMatter = useInView(section03Ref, {once: false});



    useEffect(() => {
        let Engine = Matter.Engine;
        let Render = Matter.Render;
        let World = Matter.World;
        let Bodies = Matter.Bodies;
        let Composite = Matter.Composite;
        if (isInViewMatter) {
        
            let engine = Engine.create();
            engine.gravity.scale = 0.0005;
        
            let render = Render.create({
                engine: engine,
                canvas: canvasRef.current,
                options: {
                    background: '#fff',
                    width: window.innerWidth,
                    height: window.innerHeight + 100,
                    wireframes: false
                }
            });
            Common.setDecomp(require('poly-decomp'));
            
            const staticBottom = Bodies.rectangle(
                window.innerWidth / 2,
                (window.innerHeight + 100) - 5,
                window.innerWidth - 10,
                5,
                {
                    isStatic: true,
                        restitution: 0,
                    friction: 0.5,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
            const staticRight = Bodies.rectangle(
                getWidth(390),
                (window.innerHeight + 100) / 2,
                5,
                (window.innerHeight + 100)*2,
                {
                    isStatic: true,
                        restitution: 0,
                    friction: 0.5,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
            const staticLeft = Bodies.rectangle(
                0,
                (window.innerHeight + 100) / 2,
                5,
                (window.innerHeight + 100)*2,
                {
                    isStatic: true,
                        restitution: 0,
                    friction: 0.5,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
        
            function getWidth(width) {
                return window.innerWidth * (width / 390);
            }
            function getHeight(height) {
                return window.innerHeight * (height / 844);
            }
        
            const world = engine.world;
            const boxes = [
                {
                    w: 134,
                    h: 24,
                    elem: matterRef01.current,
                    body: Bodies.rectangle(getWidth(8), -100, getWidth(90), getHeight(127), { 
                        chamfer: { radius: [45] },
                        restitution: 0,
                        friction: 0.5,
                        render: {
                            fillStyle: "#9E9EEB"
                        }
                    }),
                },
                {
                    w: 160,
                    h: 24,
                    elem: matterRef02.current,
                    body: Bodies.rectangle(getWidth(109), -100, getWidth(82), getHeight(164), { 
                        chamfer: { radius: [18] },
                        restitution: 0,
                        friction: 0.5,
                        render: {
                            fillStyle: "#A1C0F5"
                        }
                    }),
                },
                {
                    w: 190,
                    h: 89,
                    elem: matterRef03.current,
                    body: Bodies.rectangle(getWidth(54), 0, getWidth(151), getHeight(189), { 
                        chamfer: { radius: [63] },
                        restitution: 0,
                        friction: 0.5,
                        render: {
                            fillStyle: "#FFD130"
                        }
                    }),
                }
            ]

            // (S) svg
            const select = function(root, selector) {
                return Array.prototype.slice.call(root.querySelectorAll(selector));
            };

            const loadSvg = function(url) {
                return fetch(url)
                    .then(function(response) { return response.text(); })
                    .then(function(raw) { return (new window.DOMParser()).parseFromString(raw, 'image/svg+xml'); });
            };

            World.add(engine.world, [
                staticBottom,
                staticRight,
                staticLeft,
            ]);
            const elemArr = document.querySelectorAll(".rect-text");
            let itemIdx = 0;
            ([
                process.env.PUBLIC_URL + '/image/mobile/img_solution_svg_01.svg',
                process.env.PUBLIC_URL + '/image/mobile/img_solution_svg_02.svg',
                process.env.PUBLIC_URL + '/image/mobile/img_solution_svg_03.svg',
                process.env.PUBLIC_URL + '/image/mobile/img_solution_svg_04.svg',
            ]).forEach(function(path, i, array) { 
                loadSvg(path).then(function(root) {
                    itemIdx++;
                    const color = ['#E6393B', '#FF60AE', '#BCCF82', '#339F30', '#5275D3'];

                    const vertexSets = select(root, 'path').map(function(path) { 
                        return Vertices.scale(Svg.pathToVertices(path, 10), 1, 1); 
                    });

                    boxes.push({
                        w: 160,
                        h: 24,
                        elem: elemArr[i],
                        body: Bodies.fromVertices((i+1) * 60, i*60, vertexSets, {
                            restitution: 0,
                            friction: 0.5,
                            render: {
                                fillStyle: color[i],
                                strokeStyle: color[i],
                                lineWidth: 1,
                            }
                        }, true),
                        
                    })
                    
                    if (itemIdx == array.length) {
                        boxes.forEach((el, idx) => {
                            Composite.add(world, el.body);
                        })
                    }
                });
            });
            // (E) svg

            (function rerender() {
                boxes.forEach((el) => {
                    el.render = function(){
                        const {x, y} = this.body.position;
                        this.elem.style.top = `${y}px`;
                        this.elem.style.left = `${x}px`;
                        // this.elem.style.transform = `translate(${x}px,${y}px)`;
                    }
                    el.render();
                })
                Matter.Engine.update(engine);
                requestAnimationFrame(rerender);
            })();
            

        
            Matter.Runner.run(engine);
            Render.run(render);
        }
    },[isInViewMatter]);

    let prevVal = 0;
    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        setIsScroll(true);        
        setTimeout(() => {
            if (prevVal == latest) {
                setIsScroll(false);
            }
        }, 1200);
        prevVal = latest;
    })
    return (
        <section className="SolutionSectionPopee" ref={sectionRef}>
            <div className="container">
                <div className="sticky">
                    <div className="bg">
                        <img
                            src={process.env.PUBLIC_URL + '/image/mobile/img_bg_solution_popee.png'}
                        />
                    </div>
                </div>
                <SolutionIntroduceIntro
                    num="02"
                    msg="성공적인 페이퍼리스 팝업스토어 솔루션"
                    name="POPEE"
                />
            </div>
            <div className="contents">
                {/* section02 */}
                <motion.section
                    className="section02"
                    viewport={{ once: false }}
                    whileInView="visible"
                    initial="hidden"
                    animate="rest">
                    <div className="big-title">
                        <img
                            src={process.env.PUBLIC_URL + '/image/mobile/img_solution_big_text.png'}
                            alt=""
                        />
                    </div>
                    <motion.div
                        className="bg-img"
                        variants={{
                            hidden: {
                                width: '0vw',
                                transition: {
                                    duration: 1,
                                    delay: 0
                                }
                            },
                            visible: {
                                width: '100vw',
                                transition: {
                                    duration: 3,
                                    delay: 0.2,
                                    ease: 'easeInOut'
                                }
                            }
                        }}>
                        <img
                            src={
                                process.env.PUBLIC_URL + '/image/mobile/img_solution_popee_line.png'
                            }
                        />
                    </motion.div>

                    <div className="popee-info-box">
                        <ul>
                            <motion.li variants={unionVariants}>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/image/mobile/img_solution_union_01.png'
                                    }
                                    alt="참여형 인터렉션"
                                />
                            </motion.li>
                            <motion.li variants={unionVariants}>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/image/mobile/img_solution_union_02.png'
                                    }
                                    alt="정보 전달 및 수집"
                                />
                            </motion.li>
                        </ul>
                    </div>

                    <div className="small-msg text-box">
                        <div className="text-box-top flex-column">
                            <Typography txt="POPEE는 성공적인 팝업스토어를 위한" />
                            <Typography txt="디지털 콘텐츠 제작 을 제안합니다." />
                        </div>
                        <div className="text-box-bottom flex-column">
                            <Typography txt="팝업 스토어 운영 시, 퀄리티 있는 디지털 콘텐츠는" />
                            <Typography txt="고객들로부터 하여금 브랜드의 긍정적인 이미지를 재고하며," />
                            <Typography txt="정보 수집을 위한 필수 요소가 됩니다." />
                        </div>
                    </div>
                </motion.section>

                <section className={`section03${isScroll ? ' scroll' : ''}`} ref={section03Ref}>
                    <div className={`painter-bg-01${isScroll ? ' scroll' : ''}`}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 390 77"
                            fill="none">
                            <path
                                d="M-358.698 46.2684H-365V76.4272L390 76.4272V17.2404L380.722 15.3676H376.696L372.495 9.00025H365.843L352.714 6.94019L344.311 5.06744L339.41 0.5728H328.207H317.003H305.275L293.371 5.06744L287.77 2.63281L277.967 5.06744L269.914 9.00025H263.087H253.809L247.157 11.2476L240.856 9.00025L232.628 11.2476L226.676 13.3076H217.399L209.171 9.00025L198.843 5.06744L179.062 13.3076L164.533 15.3676H157.531L154.205 17.2404L144.227 15.3676L122.17 19.3005L112.192 21.7351L98.3631 19.3005L90.4858 21.7351H76.1315L67.5539 23.9824L55.3003 27.1661L42.8716 30.5371L38.4953 27.1661L26.2416 23.9824H15.7385L8.03619 27.1661L0.508942 21.7351L-11.3946 23.9824H-21.3726L-29.6 21.7351L-38.5277 21.7351L-50.7813 19.3005H-59.0088H-65.3107L-74.2383 21.7351L-81.2404 23.9824L-91.3934 21.7351L-98.0454 19.3005H-105.398L-115.201 23.9824L-125.529 27.1661H-132.531L-137.782 23.9824L-142.334 27.1661L-151.611 33.159L-160.539 35.219L-179.445 43.0847H-186.972L-196.25 48.3284H-205.527L-216.731 50.763L-223.033 50.763L-233.711 48.3284L-246.14 48.3284H-260.494H-269.947H-290.603H-299.706H-307.758L-327.539 46.2684L-335.241 46.2684H-346.444H-351.696H-358.698Z"
                                fill="black"
                            />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 390 77"
                            fill="none">
                            <path
                                d="M-358.698 46.2684H-365V76.4272L390 76.4272V17.2404L380.722 15.3676H376.696L372.495 9.00025H365.843L352.714 6.94019L344.311 5.06744L339.41 0.5728H328.207H317.003H305.275L293.371 5.06744L287.77 2.63281L277.967 5.06744L269.914 9.00025H263.087H253.809L247.157 11.2476L240.856 9.00025L232.628 11.2476L226.676 13.3076H217.399L209.171 9.00025L198.843 5.06744L179.062 13.3076L164.533 15.3676H157.531L154.205 17.2404L144.227 15.3676L122.17 19.3005L112.192 21.7351L98.3631 19.3005L90.4858 21.7351H76.1315L67.5539 23.9824L55.3003 27.1661L42.8716 30.5371L38.4953 27.1661L26.2416 23.9824H15.7385L8.03619 27.1661L0.508942 21.7351L-11.3946 23.9824H-21.3726L-29.6 21.7351L-38.5277 21.7351L-50.7813 19.3005H-59.0088H-65.3107L-74.2383 21.7351L-81.2404 23.9824L-91.3934 21.7351L-98.0454 19.3005H-105.398L-115.201 23.9824L-125.529 27.1661H-132.531L-137.782 23.9824L-142.334 27.1661L-151.611 33.159L-160.539 35.219L-179.445 43.0847H-186.972L-196.25 48.3284H-205.527L-216.731 50.763L-223.033 50.763L-233.711 48.3284L-246.14 48.3284H-260.494H-269.947H-290.603H-299.706H-307.758L-327.539 46.2684L-335.241 46.2684H-346.444H-351.696H-358.698Z"
                                fill="black"
                            />
                        </svg>
                    </div>
                    <div className="text-container">
                        <strong>
                            POPEE는 고객의 눈을 사로잡는
                            <br />
                            다양한 효과를 빠르게 제공합니다.
                        </strong>
                        <p>
                            POPEE가 제공하는 인터렉티브 디지털 브로셔는 기존 종이 브로셔
                            <br />및 PDF 브로셔에서 보여주지 못하는 애니메이션 효과 및
                            <br />
                            멀티미디어 기능을 통해 다양한 정보를 사용자에게 제공합니다.
                        </p>
                    </div>
                    <canvas id="canvas" ref={canvasRef} />
                    <div className="rect-text">
                        <span>이미지 시퀀스</span>
                    </div>
                    <div className="rect-text">
                        <span>비디오</span>
                    </div>
                    <div className="rect-text">
                        <span>트위터 연동</span>
                    </div>
                    <div className="rect-text">
                        <span>팝업 윈도우</span>
                    </div>
                    <div className="rect-text" ref={matterRef01}>
                        <span>고품질 오디오</span>
                    </div>
                    <div className="rect-text" ref={matterRef02}>
                        <span>AR/VR 3D 콘텐츠</span>
                    </div>
                    <div className="rect-text" ref={matterRef03}>
                        <span>스크롤 콘텐츠</span>
                        <span>슬라이드 쇼</span>
                    </div>

                    <div className={`painter-bg${isScroll ? ' scroll' : ''}`}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/image/mobile/img_solution_bg_paper_bottom.png'
                            }
                        />
                    </div>
                </section>

                <motion.section
                    className="section04"
                    viewport={{ once: false }}
                    whileInView="visible"
                    initial="hidden"
                    animate="rest">
                    <div className="contents">
                        <SolutionInfoIntro
                            text={textArr}
                            img={process.env.PUBLIC_URL + '/image/img_solution_popee.webp'}
                        />
                        <div className="solution-introduction">
                            <div className="simple-text">
                                방문자의 눈길을 사로잡는 애니메이션 효과를
                                <br />
                                만날 수 있는 팝업스토어를 위한
                                <br />
                                디지털 퍼블리싱 솔루션 POPEE입니다.
                            </div>

                            <div className="detail-text">
                                <p>
                                    팝업스토어를 위한 디지털 퍼블리싱 솔루션 POPEE는 이미지, 동영상,
                                    소리 등의 기존의 효과뿐 아니라 AR, VR 기술을 접목하여
                                    팝업스토어에 방문한 고객의 눈길을 잡을 수 있는 태블릿용
                                    솔루션입니다. 쉽고 간편한 콘텐츠 제작은 물론 방문자가 어떤
                                    페이지에서 얼마만큼의 시간 동안 머물렀는지 파악할 수 있는
                                    통계까지 제공합니다. 단기간에 임팩트 있는 인상을 남겨야 하는
                                    팝업스토어. 태블릿 대여까지 선택 사항에 따라 파일 공유 및 추출이
                                    차단할 수 있으며 암호화된 형태로 합리적인 솔루션 비용 안에서
                                    모두 제공하는 POPEE를 만나보세요.
                                </p>

                                <a href="" className="btn-link">
                                    POPEE 보러가기
                                </a>
                            </div>
                        </div>
                        <motion.div
                            className="section04-bg"
                            variants={{
                                hidden: {
                                    opacity: 0,
                                    transition: {
                                        duration: 1,
                                        delay: 0
                                    }
                                },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                        delay: 0.5,
                                        ease: 'easeIn'
                                    }
                                }
                            }}>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/image/mobile/img_solution_popee_section04_bg.png'
                                }></img>
                        </motion.div>
                    </div>
                </motion.section>
            </div>
        </section>
    );
}
