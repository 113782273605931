export default function QuestionWrap(props) {
    return (
        <div className={'QuestionWrap contact-question-wrap'}>
            <span className="chat-time">{props.nowTime[props.idx]}</span>
            <div className="chat-question">
                {props.question}
                { props.result === 'success' && <img src={process.env.PUBLIC_URL + '/image/img_icon_heart_hands.webp'}></img> }
            </div>
            {props.box === 'submit' && (
                <>
                    { props.result === 'success' 
                    ? 
                    <div className="chat-question">
                        담당자 연결 후 빠른 시일 내 연락 드리겠습니다.
                    </div> 
                    : 
                    <>
                    <div className="chat-question">
                        오류가 발생하였습니다. 다시 시도해주세요.
                    </div>
                    <div className="chat-question">
                        지속적으로 오류가 발생할 경우
                        <br/><a href="mailto:contact@appknot.com">contact@appknot.com</a>로 문의해주세요.
                    </div>
                    </>
                    }
                </>
            )}
        </div>
    );
}
