import { useContext, useRef } from 'react';
import Typography from '../Typography';
import Tag from '../Tag';
import { GnbContext } from '../Pc';
import { useTransform, useScroll, motion, useMotionValueEvent } from 'framer-motion';
import LinkWithTransition from '../LinkWithTransition';

export default function MainSectionAbout(props) {
    const aboutRef = useRef();
    const gnbContext = useContext(GnbContext);

    const { scrollYProgress } = useScroll({
        target: aboutRef,
        offset: ['0vh', '100vh']
    });
    const aboutRefScrollForY = useScroll({
        target: aboutRef,
        offset: ['start start', 'end end']
    });
    const scale = useTransform(scrollYProgress, [0, 1], [0.3, 1]);
    const y = useTransform(scrollYProgress, [0, 1], ['4.5625rem', '0rem']);
    const imgScroll = useTransform(
        aboutRefScrollForY.scrollYProgress,
        [0, 1],
        ['center 0', 'center -100vh']
    );
    const borderRadius = useTransform(scrollYProgress, [0, 1], ['30px', '0px']);

    useMotionValueEvent(scale, 'change', (latest) => {
        if (latest >= 1) {
            gnbContext.actions.setGnbColor('white');
        } else {
            gnbContext.actions.setGnbColor('black');
        }
    });

    return (
        <section className="MainSectionAbout" ref={aboutRef}>
            <div className="contents-wrap">
                <div className="about-bg-container">
                    <div className="about-bg">
                        <motion.div
                            className="clip-container"
                            style={{
                                x: '-50%',
                                y: y,
                                scale: scale,
                                borderRadius: borderRadius
                            }}>
                            <motion.img
                                style={{
                                    objectPosition: imgScroll
                                }}
                                src={process.env.PUBLIC_URL + '/image/img_bg_main_about.webp'}
                                alt=""
                            />
                        </motion.div>
                    </div>
                </div>
                {/* slogan */}
                <div className="slogan" id="aboutSlogan">
                    <div className="marquee-wrap">
                        <div className="slogan-text">
                            geeweknomics
                            <Tag className={'innovative'} name={'Innovative'} />
                            <Tag className={'creative'} name={'Creative'} />
                            <Tag className={'confidence'} name={'Confidence'} />
                            <Tag className={'experience'} name={'Experience'} />
                        </div>
                        <div className="slogan-text">
                            geeweknomics
                            <Tag className={'innovative'} name={'Innovative'} />
                            <Tag className={'creative'} name={'Creative'} />
                            <Tag className={'confidence'} name={'Confidence'} />
                            <Tag className={'experience'} name={'Experience'} />
                        </div>
                    </div>
                </div>
                <div className="about-contents">
                    <div className="contents">
                        <Typography className="unleashing" txt="Unleashing" />
                        <Typography className="innovation" txt="Innovation" />

                        <div className="small-msg-01">
                            <div className="en flex-column">
                                <Typography className="right" txt="We'll" />
                                <Typography txt="make you what you want," />
                                <Typography txt="whatever." />
                            </div>
                            <div className="ko">
                                <Typography txt="뭐, 원하는 것을 만들어 드리죠." />
                                <Typography txt="그게 무엇이든지 간에." />
                            </div>
                        </div>

                        <div className="small-msg-02">
                            <div className="en flex-column">
                                <Typography className="right" txt="Show you" />
                                <Typography txt="limitless possibilities with" />
                                <Typography txt="a geeky mind" />
                            </div>
                            <div className="ko">
                                <Typography txt="괴짜 같은 마인드로" />
                                <Typography txt="무한한 가능성을 보여드릴게요." />
                            </div>
                        </div>

                        <Typography className="with-a" txt="with a" />
                        <Typography className="touch-of" txt="Touch of" />
                        <Typography className="geekiness" txt="Geekiness!" />
                        <LinkWithTransition to="/about" className="about-video">
                            <div className="video-wrap">
                                <video muted playsInline loop autoPlay src={ process.env.PUBLIC_URL + '/image/vid_btn_about.mp4' }>
                                    <source src={ process.env.PUBLIC_URL + '/image/vid_btn_about.mp4' }></source>
                                </video>
                            </div>
                            <p className="video-text">more about us</p>
                        </LinkWithTransition>
                    </div>
                </div>
            </div>
        </section>
    );
}
