import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';
import { debounce } from 'lodash';

export const MatterJs = () => {
    const boxRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        let Engine = Matter.Engine;
        let Render = Matter.Render;
        let World = Matter.World;
        let Bodies = Matter.Bodies;
        let Body = Matter.Body;
        let Mouse = Matter.Mouse;
        let MouseConstraint = Matter.MouseConstraint;
        let Composite = Matter.Composite;
    
        let engine = Engine.create();
    
        let render = Render.create({
            engine: engine,
            canvas: canvasRef.current,
            options: {
                background: '#fff',
                width: window.innerWidth,
                height: window.innerHeight,
                wireframes: false
            }
        });
    
        setTimeout(() => {
            engine.gravity.scale = 0;
            const staticTop = Bodies.rectangle(
                window.innerWidth / 2,
                getHeight(-200),
                window.innerWidth - 10,
                5,
                {
                    isStatic: true,
                    render: {
                        fillStyle: 'none'
                    }
                }
            );
            World.add(engine.world, [
                staticTop,
            ]);
        }, 1400);
    
        const staticBottom = Bodies.rectangle(
            window.innerWidth / 2,
            window.innerHeight - 5,
            window.innerWidth - 10,
            5,
            {
                isStatic: true,
                render: {
                    fillStyle: 'none'
                }
            }
        );
        const staticRight = Bodies.rectangle(
            window.innerWidth,
            window.innerHeight / 2,
            5,
            window.innerHeight*2,
            {
                isStatic: true,
                render: {
                    fillStyle: 'none'
                }
            }
        );
        const staticLeft = Bodies.rectangle(
            0,
            window.innerHeight / 2,
            5,
            window.innerHeight*2,
            {
                isStatic: true,
                render: {
                    fillStyle: 'none'
                }
            }
        );
    
        function getWidth(width) {
            return window.innerWidth * (width / 1920);
        }
        function getHeight(height) {
            return window.innerHeight * (height / 1080);
        }
    
        // x, y, radius
        const body1 = Bodies.circle(getWidth(223), getHeight(-200), getWidth(706/2), {
            restitution: 1,
            friction: 0.5,
            render: {
                strokeStyle: '#FFFFFF',
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/img_symbol_01.webp',
                    xScale: window.innerWidth / 1920,
                    yScale: window.innerWidth / 1920
                }
            },
        });
        // x, y, sides, radius
        const body2 = Bodies.polygon(getWidth(window.innerWidth - 641), getHeight(-200), 3.3, getWidth(560/2), {
            restitution: 0.7,
            friction: 0.5,
            render: {
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/img_symbol_02.webp',
                    xScale: window.innerWidth / 1920,
                    yScale: window.innerWidth / 1920
                }
                
            },
        });
    
        // x, y, width, height, slope
        const body3 = Bodies.trapezoid(getWidth(100), getHeight(-130), getWidth(508), getWidth(592), 1, {
            restitution: 0.7,
            friction: 0.5,
            render: {
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/img_symbol_03.webp',
                    xScale: window.innerWidth / 1920,
                    yScale: window.innerWidth / 1920
                }
            }
        });
    
        // x, y, width, height
        const body4 = Bodies.rectangle(getWidth(window.innerWidth - 641), getHeight(-150), getWidth(641), getWidth(568), {
            restitution: 0.7,
            render: {
                lineWidth: 1,
                sprite: {
                    texture:
                        process.env.PUBLIC_URL + '/image/img_symbol_04.webp',
                    xScale: window.innerWidth / 1920,
                    yScale: window.innerWidth / 1920
                }
            }
        });
    
        Body.rotate(body2, 0);
        Body.rotate(body3, 1.5);
    
        World.add(engine.world, [
            // staticTop,
            staticBottom,
            staticRight,
            staticLeft,
            body1,
            body2,
            body3,
            body4
        ]);
        var mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });
        mouseConstraint.mouse.element.removeEventListener(
            'mousewheel',
            mouseConstraint.mouse.mousewheel
        );
        mouseConstraint.mouse.element.removeEventListener(
            'DOMMouseScroll',
            mouseConstraint.mouse.mousewheel
        );
    
        Composite.add(engine.world, mouseConstraint);
        render.mouse = mouse;
    
        // Engine.run(engine);
        Matter.Runner.run(engine);
        Render.run(render);
        window.addEventListener('resize', debounce(() => {
            canvasRef.width = window.innerWidth;
            canvasRef.height = window.innerHeight;
            render.bounds.max.x = window.innerWidth;
            render.bounds.max.y = window.innerHeight;
            render.options.width = window.innerWidth;
            render.options.height = window.innerHeight;
            render.canvas.width = window.innerWidth;
            render.canvas.height = window.innerHeight;
        }, 250));
    },[]);

    return (
        <div className="MatterJs" ref={boxRef}>
            <canvas id="canvas" ref={canvasRef} />
        </div>
    );
};
