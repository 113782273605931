import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Mobile from './mobile/components/Mobile';
import Pc from './pc/components/Pc';

export const GnbContext = createContext();
export const TransitionContext = createContext();
function App() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (/Android|iPhone/i.test(navigator.userAgent)) {
            setIsMobile(true);
        }
    },[]);

    return (
        <div className="App">
            <BrowserRouter>
                {
                    isMobile ?  <Mobile/> : <Pc/>
                }
            </BrowserRouter>
        </div>
    );
}

export default App;
