const serviceBtnList = [
    {
        id: "mobileApp",
        value: "mobile-app",
        text: "사용자를 고려한",
        text02: "을 만들고 싶어요.",
        serviceType: "모바일 앱",
        src: "image/img_icon_mobile_phone.webp"
    },
    {
        id: "webSite",
        value: "web-site",
        text: "누구나 편리한",
        text02: "를 만들고 싶어요.",
        serviceType: "웹사이트",
        src: "image/img_icon_spider_web.webp"
    },
    {
        id: "systemChange",
        value: "system-change",
        text: "기존",
        text02: "하고싶어요.",
        serviceType: "시스템을 개편",
        src: "image/img_icon_woman_technologist.webp"
    },
    {
        id: "digitalMarketing",
        value: "digital-marketing",
        text: "꽂히는",
        text02: "이 필요해요.",
        serviceType: "디지털 마케팅",
        src: "image/img_icon_face_with_raised_eyebrow.webp"
    },
    {
        id: "design",
        value: "design",
        text: "기가 막힌",
        text02: "이 필요해요.",
        serviceType: "디자인",
        src: "image/img_icon_man_artist.webp"
    },
    {
        id: "withAppknot",
        value: "with-appknot",
        box: "withAppknot",
        text: "여기에 목록이 없지만",
        text02: "앱노트와 함께 일하고 싶어요.",
        src: "image/img_icon_woman_dancing.webp"
    }  
];

const budgetBtnList = [ "5천만원 미만", "5천 ~ 1억", "1억 ~ 3억", "5억 ~ 9억", "10억 초과", "미정" ];
const openDayList = ["3개월 이내", "3~5개월 후", "6~9개월 후", "9개월 이상"];
const inputList = [
    {
        name: "name",
        placeholder: "이름을 입력하세요.",
    },
    {
        name: "position",
        placeholder: "직책을 입력하세요.",
    },
    {
        name: "company",
        placeholder: "회사명을 입력하세요.",
    },
    {
        name: "phone",
        placeholder: "연락처를 입력하세요.",
    },
    {
        name: "email",
        placeholder: "이메일 주소를 입력하세요."
    }
]

export { serviceBtnList, budgetBtnList, openDayList, inputList };