import Typography from '../Typography';
import { useInView, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRef, useContext } from 'react';
import { GnbContext } from '../Pc';
export default function ContactSectionSummary() {
    const gnbContext = useContext(GnbContext);
    const textView = useRef();
    const sectionRef = useRef();
    const isInViewSection = useInView(sectionRef);
    const isInViewText = useInView(textView, { once: false }, { amount: 0.8 });
    const { scrollYProgress } = useScroll({ target: sectionRef });
    useMotionValueEvent(scrollYProgress, 'change', (latest) => {
        if (sectionRef.current.getBoundingClientRect().top <= 0) {
            gnbContext.actions.setGnbColor('black');
            if (isInViewSection) {
                gnbContext.actions.setGnbColor('black');
            }
        } else {
            gnbContext.actions.setGnbColor('white');
        }
    });
    return (
        <section className={`ContactSectionSummary ${isInViewText && 'white'}`} ref={sectionRef}>
            <div className="contact-summary-container">
                <div className="text-container">
                    <div className="small-msg text01" ref={textView}>
                        <div className="flex-column">
                            <Typography className="right" txt="We're a little bit of a freak," />
                            <Typography txt="but our passion and ability to work" />
                            <Typography className="right" txt="leads you to great results." />
                        </div>
                    </div>
                    <div className="small-msg text02">
                        <div className="text-ko flex-column">
                            <Typography
                                className="right"
                                txt="앱노트는 다양한 관점으로 아이디어를 제시합니다."
                            />
                            <Typography txt="각각의 구성원은 독립적인 사고와 창의력을 지니고 있으며, 이러한 다양성이 우리 프로젝트를" />
                            <Typography txt="독특하고 혁신적으로 만들어냅니다." />
                            <Typography txt="어떠한 어려움이든, 우리는 항상 문제를 해결하고 이를 실현하기 위한 방법을 찾아냅니다." />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
