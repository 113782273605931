import { GnbContext } from '../components/Mobile';
import { useContext, useEffect, useRef, useState } from 'react';
import AboutSectionIntro from '../components/About/AboutSectionIntro'; 
import AboutSectionAbout from '../components/About/AboutSectionAbout';
import AboutSectionCard from '../components/About/AboutSectionCard';
import AboutSectionWorkWeDo from '../components/About/AboutSectionWorkWeDo';

export default function AboutPage() {
    const gnbContext = useContext(GnbContext);
    useEffect(() => {
        // gnbContext.actions.setGnbColor('white');
    }, []);

    return (
        <div className="AboutPage">
            <AboutSectionIntro />
            <AboutSectionAbout />
            <AboutSectionCard />
            <AboutSectionWorkWeDo/>
        </div>
    );
}
