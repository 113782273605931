import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';
import { motion } from 'framer-motion';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default function Daldabang() {
    const wdp = workDetailPath();
    const wp = wdp + 'ddb/';

    return (
        <div className="work-detail-wrap daldabang">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className='award-mark'>
                        <img src={wp + 'img_award_ddb.webp'} />
                    </div>
                    <div className="container">
                        <h2 className="project-type">Yuhan Kimberly</h2>
                        <h4 className="project-name">Daldabang</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                달다방은 생리 주기 관리를 위한 캘린더와 여성 생리 건강 정보를 제공해
                                주는 애플리케이션입니다.
                                <br />
                                <br />
                                기본적으로 생리 주기 관리 서비스이기 때문에 예상 가임기, 예상 생리
                                주기 등 정확한 예측 정보를 고객에게 전달하는 것을 핵심으로 서비스를
                                기획했습니다.
                                <br />
                                <br />
                                정확한 예측 정보를 위해서는 주기 계산법과 데이터 확보가 핵심
                                요소입니다. ‘대한 산부인과 의사회’에서 적용한 계산법을 이용한
                                평균값을 도출하여 주기를 예상하도록 계산 로직을 기획했고, 피임약
                                복용 등에 따른 변수도 로직에 적용했습니다. 또한 사용자가 꾸준히
                                기록할 수 있도록 예상 생리 주기에 푸시 알림을 설정할 수도 있고, 어떤
                                화면에서도 빠르게 입력하는 화면으로 이동할 수 있는 셰이크(Shake)
                                기능을 제공합니다.
                                <br />
                                <br />이 외에도 다양한 여성 건강 소식과 여성 맞춤 트렌드 및 이벤트
                                정보를 제공함으로써 앱의 사용률과 재방문율을 증가시키고자 했습니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_01_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_01_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>

                    <Fadeup className="img-02">
                        <img src={wp + 'img_02_02.png'} />

                        <div className="img-wrap">
                            <Fadeup className="img-02-01">
                                <img src={wp + 'img_02_02_01.png'} />
                            </Fadeup>
                            <Fadeup className="img-02-02">
                                <img src={wp + 'img_02_02_02.png'} />
                            </Fadeup>
                        </div>
                    </Fadeup>

                    <Fadeup className="img-03">
                        <img src={wp + 'img_02_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-03">
                    <motion.div
                        className="graph-line"
                        whileInView={{
                            width: '100%',
                            transition: {
                                duration: 1.5,
                                ease: 'linear'
                            }
                        }}
                        viewport={{ once: false }}>
                        <img src={wp + 'img_03_bg_graph_line.png'} />
                    </motion.div>
                    <div className="graph-bar-wrap">
                        <motion.div
                            className="graph-bar"
                            initial={{
                                scaleY: 0
                            }}
                            whileInView={{
                                scaleY: 1,
                                transition: {
                                    duration: 1.5,
                                    ease: 'linear'
                                }
                            }}>
                            <img src={wp + 'img_03_bg_graph_bar.png'} />
                        </motion.div>
                    </div>
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-04">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_04_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_04_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_04_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-05">
                    <div className="img-01">
                        <img src={wp + 'img_05_01.png'} />
                    </div>
                    <Swiper
                        className="img-slide"
                        loop={true}
                        autoplay={{ delay: 2000, disableOnInteraction: false }}
                        modules={[Autoplay]}
                        slidesPerView={3.4}
                        centeredSlides={true}
                        spaceBetween={6}>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_01.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_02.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_03.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_04.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_05.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_06.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_07.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_05_slide_08.png'} />
                        </SwiperSlide>
                    </Swiper>
                    <div className="img-border">
                        <img src={wp + 'img_05_01_border.png'} />
                    </div>
                </section>

                <section className="section-06">
                    <Fadeup className="img-01">
                        <img src={wp+"img_06_01.png"} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp+"img_06_02.png"} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp+"img_06_03.png"} />
                    </Fadeup>
                </section>

                <section className="section-07">
                    <Fadeup className="img-01">
                        <img src={wp + "img_logo.png"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
