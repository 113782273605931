import {
    useScroll,
    useMotionValueEvent,
    motion,
    useTransform,
    easeInOut,
    animate,
    useInView
} from 'framer-motion';
import { useRef } from 'react';

export default function SolutionSectionIntro() {
    const introRef = useRef();
    const weHaveRef = useRef();
    const stickyRef = useRef();
    const isInView = useInView(weHaveRef, {
        margin: '9999px 0px 0px 0px',
        once: false
    });

    const { scrollYProgress } = useScroll({
        target: introRef
    });

    const x = useTransform(scrollYProgress, [0, 0.5], ['-10vw', '0vw'], { ease: easeInOut });
    const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
    const iconHandly = useTransform(scrollYProgress, [0, 0.9], ['100%', '0%']);
    const iconPopee = useTransform(scrollYProgress, [0, 0.9], ['200%', '0%']);
    const iconMentor = useTransform(scrollYProgress, [0, 0.9], ['300%', '0%']);


    useMotionValueEvent(opacity, 'change', (latest) => {
        if (latest == 1) {
            animate('.SolutionSectionIntro .tag', { y: ['500%', '0%'] }, { type: 'spring' });
            animate('.SolutionSectionIntro .tag span', { opacity: [0, 1] }, { duration: 1 });
        }
        if (latest == 0) {
            animate('.SolutionSectionIntro .tag span', { opacity: [1, 0] }, { duration: 0.5 });
            animate('.SolutionSectionIntro .tag', { y: ['0%', '500%'] }, { type: 'spring' });
        }
    });
    return (
        <section
            className={`SolutionSectionIntro${isInView ? ' white' : ''}`}
            ref={introRef}>
            <div className="sticky" ref={stickyRef}>
                <div className="solution-intro-text">
                    <span ref={weHaveRef}>we have</span>
                    <motion.div className="show-text-box" style={{ x }}>
                        <motion.span className="hidden-num" style={{ opacity }}>
                            3
                        </motion.span>
                        <h2>Solutions</h2>
                    </motion.div>
                    <div className="tag handly">
                        <span>HANDLY</span>
                    </div>
                    <div className="tag popee">
                        <span>POPEE</span>
                    </div>
                    <div className="tag mentor">
                        <span>MENTOR</span>
                    </div>
                </div>
                <div className="solution-intro-text-ko">
                    자, 지금부터 우리가 제안하는 솔루션을 한번 확인해보시죠.
                </div>
                <div className="solution-intro-icon">
                    <ul className="icon-wrap">
                        <motion.li style={{ y: iconHandly }}>
                            <img src={process.env.PUBLIC_URL + '/image/img_solution_handly.webp'} />
                        </motion.li>
                        <motion.li style={{ y: iconPopee }}>
                            <img src={process.env.PUBLIC_URL + '/image/img_solution_popee.webp'} />
                        </motion.li>
                        <motion.li style={{ y: iconMentor }}>
                            <img src={process.env.PUBLIC_URL + '/image/img_solution_mentor.webp'} />
                        </motion.li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
