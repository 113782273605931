import { motion, useInView } from 'framer-motion';

export default function Styler() {
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };

    return (
        <div className="Styler">
            <div className="work-detail-wrap work-detail-container" data-hash="styler">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">THE BOOK COMPANY</h2>
                            <h4 className="project-name">주부생활</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    주부생활 애플리케이션은 더북컴퍼니의 콘텐츠를 통해 일반
                                    사용자에게 체험단 참여와 제품 홍보 활동을 유도하는 라이프 스타일
                                    리뷰 서비스입니다.
                                    <br />
                                    <br />
                                    주부생활 서비스에서는 에디터의 콘텐츠로 독자들에게 생생한 리뷰를
                                    전달하고자 하였습니다. 이를 위해 아날로그 방식의 미디어 매체를
                                    모바일 환경에 맞는 디지털 미디어 콘텐츠로 손쉽게 가공하는 데에
                                    초점을 맞춰 기획되었습니다. 이로써 구독자들은 ‘주부생활‘
                                    잡지보다 빠르고 편리하게 콘텐츠를 받아볼 수 있고 매체사는
                                    광고주의 니즈에 맞춰 신속하게 프로모션을 진행할 수 있게
                                    되었습니다. 더불어 체험단을 경험한 사용자의 2차 리뷰 콘텐츠가
                                    생성되어 서비스가 선순환될 수 있도록 구성하였습니다.
                                    <br />
                                    <br />
                                    주부생활 애플리케이션은 출시 3개월 만에 실사용자 1천 명을
                                    바라보고 있는 라이프 스타일 리뷰 서비스가 되었습니다. 향후
                                    사용자들의 리뷰 생활을 독려할 포인트/추천인 제도, 커뮤니티 기능
                                    등을 기획하여 이를 기반으로 집중 타게팅 된 프로모션을 진행할 수
                                    있는 플랫폼으로 발전해 나가고자 합니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="2">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section01_01.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section01_02.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section01_03.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                </ul>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-02">
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_style_logo.svg'
                                    }
                                    alt="주부생활 이미지"
                                />
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section02_01.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <div className="work-detail-information-img work-detail-information-img-03">
                                <div className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section02_02.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-03">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 reactive-rectangle fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section03_01.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <div className="work-detail-information-img work-detail-information-img-02">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section03_02.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="5">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section03_03.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                </ul>
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section03_04.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section03_05.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <div className="work-detail-information-img work-detail-information-img-01">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="3">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section04_01.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                    <li>
                                        <div className="img-clip-container reactive-rectangle">
                                            <video
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/video/aweb_styler_01.mp4'
                                                }
                                                muted
                                                autoPlay
                                                loop
                                                playsInline
                                                poster={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section04_02_mobile.webp'
                                                }></video>
                                            {/* <imgsrc={process.env.PUBLIC_URL +'/site/res/image/img_styler_section04_02_mobile.webp'} alt="주부생활 이미지" />  */}
                                        </div>
                                    </li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-up"
                                        data-delay="5">
                                        <motion.div
                                            variants={variants}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section04_02.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                </ul>
                            </div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-05">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section05_01.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section05_02.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up"
                                data-delay="3">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_styler_section05_03.svg'
                                    }
                                    alt="주부생활 이미지"
                                />
                            </motion.div>
                            <div className="work-detail-information-img work-detail-information-img-04">
                                <ul>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-right"
                                        data-delay="2">
                                        <motion.div
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    x: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 2.5
                                                        },
                                                        x: {
                                                            duration: 1.8
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.2,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, x: '50%' }
                                            }}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section05_04.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-right"
                                        data-delay="5">
                                        <motion.div
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    x: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 2.5
                                                        },
                                                        x: {
                                                            duration: 1.8
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.5,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, x: '100%' }
                                            }}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section05_05.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                    <motion.li
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        className="fade-right"
                                        data-delay="8">
                                        <motion.div
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    x: '0%',
                                                    transition: {
                                                        opacity: {
                                                            duration: 2.5
                                                        },
                                                        x: {
                                                            duration: 1.8
                                                        },
                                                        duration: 1.2,
                                                        delay: 0.7,
                                                        ease: 'linear'
                                                    }
                                                },
                                                hidden: { opacity: 0, x: '100%' }
                                            }}
                                            className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section05_06.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </motion.div>
                                    </motion.li>
                                </ul>
                            </div>
                            <div className="work-detail-information-img work-detail-information-img-05">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_styler_section05_07.svg'
                                    }
                                    alt="주부생활 이미지"
                                />
                            </div>
                            <div className="work-detail-information-img work-detail-information-img-06">
                                <div className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section05_08.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </div>
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-07 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section05_09.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-06">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section06_01.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                data-delay="3">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_styler_section06_02.webp'
                                    }
                                    alt="주부생활 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up"
                                data-delay="5">
                                <motion.div variants={variants} className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section06_03.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </motion.div>
                            </motion.div>
                        </div>

                        <div className="work-detail-information-container work-detail-information-container-07">
                            <div className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <div className="img-clip-container">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_styler_section07_01.webp'
                                        }
                                        alt="주부생활 이미지"
                                    />
                                </div>
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                data-delay="3">
                                <motion.ul variants={variants}>
                                    <li>
                                        <div className="img-clip-container">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/site/res/image/img_styler_section07_02.webp'
                                                }
                                                alt="주부생활 이미지"
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_style_logo_ko.svg'
                                            }
                                            alt="주부생활 이미지"
                                        />
                                    </li>
                                </motion.ul>
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
