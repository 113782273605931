import { motion, useScroll, useTransform } from "framer-motion";
import { useContext, useRef } from "react";
import RandomTypo from "../RandomTypo";
import Typography from "../Typography";
import { ContactContext } from "../Mobile";

export default function AboutSectionWorkWeDo() {
    const contactContext = useContext(ContactContext);
    
    const typoRef = useRef();
    const contactBtnRef = useRef();

    const typoRefScroll = useScroll({
        target: typoRef,
        offset: ["start end", "end end"]
    });
    
    const bgY = useTransform(typoRefScroll.scrollYProgress, [0, 1], ["100%", "13%"]);
    const icnGap = useTransform(typoRefScroll.scrollYProgress, [0.5, 1], ["13.0208333333vw", "0vw"]);
    
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.8,
                staggerChildren: 0.09,
                
            }
        }
    }
    const item = {
        hidden: { 
            opacity:0,
            x: "50%" 
        },
        show: { 
            opacity:1,
            x: "0%",
            transition: {
                ease: "linear",
            } 
        }
    }

    const hiddenCard = {
        hidden:{opacity : 1},
        show:{
            opacity:0,
            transition:{
                ease: "linear",
                delay: 1.5,
            }
        }
    }

    return (
        <motion.div className="AboutSectionWorkWeDo">
            <motion.div className="sticky large-text-container" variants={container} whileInView="show" viewport={{amount: 0.9, margin: "999px 0px 0px 0px"}} initial="hidden">
                <motion.div variants={item}>s</motion.div>
                <motion.div variants={item}>o</motion.div>
                <motion.div variants={item}>,</motion.div>
                <br />
                <motion.div variants={item}>w</motion.div>
                <motion.div variants={item}>h</motion.div>
                <motion.div variants={item}>a</motion.div>
                <motion.div variants={item}>t</motion.div>
                <br />
                <motion.div variants={item}>w</motion.div>
                <motion.div variants={item}>e</motion.div>
                <div className="space"></div>
                <motion.div variants={item}>d</motion.div>
                <motion.div variants={item}>o</motion.div> 
                <motion.div variants={item}>?</motion.div>
            </motion.div>
            <div className="scroll-contents we-do">
                <motion.div variants={container} whileInView="show" viewport={{amount:"all",once:"true"}} className="card-wedo-01">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_01.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>WE DO UI/UX</span>

                        <p>
                            같은 기능을 한다면, 더 아름다운 서비스에 눈이 가는 것은 당연합니다. 
                            <br/>최고의 서비스는 최고의 UI/UX 디자이너의 손에서 탄생합니다.
                        </p>
                    </motion.div>
                </motion.div>
                <motion.div variants={container} whileInView="show" viewport={{amount:"all",once:"true"}} className="card-wedo-02">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_02.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>we do native/hybrid <br/>application</span>

                        <p className="center">
                            (잠깐, 새로운 기능 개발중)
                        </p>
                    </motion.div>
                </motion.div>
                <motion.div variants={container} viewport={{amount:"all",once:"true"}} whileInView="show" className="card-wedo-03">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_03.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>we do website&application</span>

                        <p>
                            지금 보고 있는 웹사이트가 
                            <br/>대답이 될까요? 😏
                        </p>
                    </motion.div>
                </motion.div>
                <motion.div variants={container} viewport={{amount:"all",once:"true"}}  whileInView="show" className="card-wedo-04">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_04.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>we do solution</span>

                        <p>
                            디지털 라이브러리 솔루션, 
                            <br/>팝업스토어를 위한 디지털 퍼블리싱 솔루션, 
                            <br/>방문자를 위한 솔루션!
                            <br/>우리는 오늘도 더 나은 내일을 만들기 위해 
                            <br/>세상에 없던 해답을 만들고 있어요.
                        </p>
                    </motion.div>
                </motion.div>
                <motion.div variants={container} viewport={{amount:"all",once:"true"}} whileInView="show" className="card-wedo-05">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_05.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>we do digital marketing</span>

                        <p>
                            완성된 서비스를 훌륭히 궤도에 오르게 하는 
                            <br/>마케팅 전문가 그룹 knotters도 
                            <br/>우리와 함께 하고 있죠!
                        </p>
                    </motion.div>
                </motion.div>
                <motion.div variants={container} viewport={{amount:"all",once:"true"}} whileInView="show" className="card-wedo-06">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_06.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>we do it application <br/>consulting</span>

                        <p>
                            손에 잡히지 않는 그 무엇인가, 
                            <br/>저희와 함께라면 현실로 만들어낼 수 있어요.
                        </p>
                    </motion.div>
                </motion.div>
                <motion.div variants={container} viewport={{amount:"all",once:"true"}} whileInView="show" className="card-wedo-07">
                    <img src={process.env.PUBLIC_URL + "/image/img_about_wedo_07.webp"} alt="" />
                    <motion.div variants={hiddenCard} className="hover-contents">
                        <span>we do maintanance</span>

                        <p>
                            우리는 서비스를 완성시키는데에 만족하지 않습니다. 
                            <br/>완성시키고 온전히 서비스가 되도록 닦고
                            <br/>조이고 기름칠하는데에도 언제나 진심이죠.
                        </p>
                    </motion.div>
                </motion.div>
            </div>
            <div className="scroll-contents black">
                <div className="row">
                    <RandomTypo text="HELLO, WORLD!"/>
                </div>
                <div className="row">
                    <img src={process.env.PUBLIC_URL + "/image/icn_earth.webp"} />
                    <RandomTypo text="we are"/>
                </div>
                <div className="row">
                    <RandomTypo text="appknot"/>
                </div>

                <div className="text-container en">
                    <Typography className="right" txt="so, have you solved"/>
                    <Typography txt="any questions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; about us?"/>
                </div>
                <div className="text-container ko">
                    <Typography className="right" txt="우리는 다양한 관점, 치열한 회의,번뜩이"/>
                    <Typography txt="는 아이디어, 그 끝에는 최고의 결과로 당신의 상상을 실현해낼거에요."/>
                    <Typography txt="당신이 상상하고 있는 그 무엇인가를 우리와 함께 발견할 수 있기를 기"/>
                    <Typography txt="대하고 있어요!"/>
                </div>
                <div className="typo" ref={typoRef}>
                    WANT TO WORK
                    <br/>TOGETHER?

                    <div className="icn-wrap">
                        <motion.div className="right" style={{marginRight: icnGap}}>
                            <img src={process.env.PUBLIC_URL + "/image/icn_right.webp"} alt="" />
                        </motion.div>
                        <div className="left">
                            <img src={process.env.PUBLIC_URL + "/image/icn_left.webp"} alt="" />
                        </div>
                    </div>

                    <br/>
                    <button type="button" className="btn-contact" ref={contactBtnRef} onClick={() => {
                        contactContext.actions.setPopupOn(true);
                        contactContext.actions.setContactBtnRef(contactBtnRef);
                    }}>
                        LET’s TALK <img src={process.env.PUBLIC_URL + "/image/icn_raised_hand.webp"} alt="" />
                    </button>
                </div>

                <motion.div className="bg" style={{y: bgY}}>
                    <img src={process.env.PUBLIC_URL + "/image/mobile/img_bg_about_work_together_mo.webp"} />
                </motion.div>
            </div>
        </motion.div>
    )
}