import { motion, useAnimation, useMotionValueEvent, useScroll, useSpring, useTransform } from 'framer-motion';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Typography from '../Typography';
import DelayHovering from '../DelayHovering';

export default function AboutSectionAbout() {
    const sectionRef = useRef();
    const scrollRef = useRef();
    const sectionEndRef = useRef();
    const clipRef = useRef();

    const [scrollRange, setScrollRange] = useState(0);
    const [textOp, setTextOp] = useState(1);
    const [viewportW, setViewportW] = useState(0);

    const sectionRefScrollForTransform = useScroll({
        target: sectionRef,
        offset: ["100vh", "end end"],
    });

    const sectionRefScroll = useScroll({
        target: sectionRef,
        offset: ["start start","100vh"]
    });
    
    // 가로 스크롤 영역
    const controls = useAnimation();
    const transform = useTransform(sectionRefScrollForTransform.scrollYProgress, [0.1, 0.564], [0, (-scrollRange + viewportW)]);
    const clip = useTransform(sectionRefScroll.scrollYProgress, [0, 1], ["6.7708333333vw", "100vw"]);
    const spring = useSpring(transform, {
        damping: 15,
        mass: 0.27,
        stiffness: 70
    });

    useMotionValueEvent(clip, "change", (v) => {
        if (v == "100vw") {
            clipRef.current.style.clipPath = "none";
        } else {
            clipRef.current.style.clipPath = "circle(" + clip.current + " at 84vw 24vh)"
        }
    })
    useMotionValueEvent(sectionRefScroll.scrollYProgress, "change", (v) => {
        if (v > 0) {
            setTextOp(0);
        } else {
            setTextOp(1);
        }
    })
    let isChange = false;
    useMotionValueEvent(sectionRefScrollForTransform.scrollYProgress, "change", (v) => {
        if (v.toFixed(1) > 0.7) {
            if (!isChange) {
                controls.start("visible");
                isChange = true;
            }
        } else {
            if (isChange) {
                controls.start("hidden");
                isChange = false;
            }
        }
    })
    
    useEffect(() => {
        const onResize = () => {
            setViewportW(window.innerWidth);
            scrollRef && setScrollRange(scrollRef.current.scrollWidth);
        }

        onResize();
        
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    },[]);
    
    return (
        <div className="AboutSectionAbout">
            <div className="AboutSectionAbout" ref={sectionRef} style={{height: `calc(${scrollRange}px + 500px)`}}>
                <div className="container">
                    <motion.div className="scroll-wrap" ref={scrollRef} style={{x: spring}}>
                        <section className="scroll-section section-01">
                            <div className="text-container">
                                <div className="typo">
                                    <span>B</span>
                                    <span>E</span>
                                    <span>T</span>
                                    <span>T</span>
                                    <span>E</span>
                                    <span>R</span>
                                    <span> </span>
                                    <span>T</span>
                                    <span>H</span>
                                    <span>A</span>
                                    <span>N</span>
                                    <br/>
                                    <span style={{marginLeft: "13%"}}>Y</span>
                                    <span>O</span>
                                    <span>U</span>
                                    <span>R</span>
                                    <br/>
                                    <span style={{marginLeft: "50%"}}>A</span>
                                    <span>P</span>
                                    <span>P</span>
                                </div>
                                <div className={`typo-tag`}>
                                    <span>go further in the solution</span>
                                    <img src={process.env.PUBLIC_URL + "/image/icn_thinking_face.webp"} />
                                </div>

                                <div className="text-container ko">
                                    <Typography txt="우리는 솔루션에서 나아가 당신의 앱보다 더 나은 앱을"/>
                                    <Typography className="right" txt="만들기 위해 성장하고 있었습니다."/>
                                    <Typography txt="이 무렵 앱노트는 성수동에 자리를 잡았고,"/>
                                    <Typography txt="12년째 성수동에 앱노트는 뿌리를 내리고 있어요."/>
                                </div>
                            </div>

                            <div className="img-container">
                                {
                                    ["web development team", "design team", "ios edvelopment team", "project managerment team", "strategy planning team", 'android development tema'].map((item, idx) => {
                                        return (
                                            <DelayHovering delay={idx * Math.random()} className={"img-0"+(idx+1) } key={idx}>
                                                <img src={process.env.PUBLIC_URL + "/image/img_about_metal_0" + (idx+1) + ".webp"} />
                                                
                                                <div className="tag-wrap">
                                                    <div className="tag">
                                                        {item}
                                                    </div>
                                                </div>
                                            </DelayHovering>
                                        )
                                    })
                                }
                            </div>
                        </section>

                        <section className="scroll-section section-02" ref={clipRef}>
                            <div className="circle-area-text text-container en" style={{opacity: textOp}}>
                                <span className="right">Now we have</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;A lot of members&nbsp;&nbsp;</span>
                                <span>to solve problems&nbsp;&nbsp;&nbsp;</span>
                                <span className="right">together</span>
                            </div>
                            <div className="logo" style={{opacity: textOp ? 0 : 1}}>
                                <svg width="1742" height="429" viewBox="0 0 1742 429" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_616_145)">
                                    <path d="M185.437 132.927C176.687 120.201 164.863 109.839 151.04 102.782C137.218 95.7253 121.833 92.1969 106.283 92.5173C44.59 92.5173 -0.18013 142.764 -0.18013 211.886C-0.18013 281.008 44.5899 331.254 106.731 331.254C122.128 331.595 137.37 328.136 151.079 321.19C164.789 314.243 176.535 304.028 185.257 291.465V326.38H234.773V97.214H185.257L185.437 132.927ZM116.849 139.219C156.783 139.219 185.795 169.704 185.795 211.62C185.795 253.536 156.783 284.021 116.849 284.021C76.9138 284.021 49.9626 254.245 49.9626 211.62C49.9626 168.995 77.4511 139.219 116.849 139.219Z" fill="black"/>
                                    <path d="M417.883 92.5173C402.339 92.2558 386.974 95.8389 373.184 102.942C359.393 110.045 347.611 120.443 338.909 133.193V97.7457H289.393V429.266H339.088V291.11C347.823 303.667 359.57 313.882 373.274 320.842C386.979 327.801 402.214 331.288 417.615 330.988C479.576 330.988 524.525 280.831 524.525 211.62C524.525 142.409 479.576 92.5173 417.883 92.5173ZM474.383 211.886C474.383 254.511 446.894 284.287 407.497 284.287C368.099 284.287 338.461 253.802 338.461 211.886C338.461 169.969 367.472 139.485 407.497 139.485C447.521 139.485 474.383 168.995 474.383 211.62V211.886Z" fill="black"/>
                                    <path d="M690.622 92.5171C675.09 92.2544 659.737 95.8378 645.961 102.942C632.184 110.045 620.42 120.444 611.737 133.193V97.7456H562.132V429.266H611.737V291.11C620.467 303.672 632.212 313.892 645.918 320.852C659.624 327.813 674.862 331.296 690.263 330.988C752.225 330.988 797.174 280.831 797.174 211.62C797.174 142.409 752.315 92.5171 690.622 92.5171ZM747.211 211.886C747.211 254.511 719.633 284.287 680.235 284.287C640.837 284.287 611.29 253.802 611.29 211.886C611.29 169.969 640.3 139.485 680.235 139.485C720.17 139.485 747.211 168.995 747.211 211.62V211.886Z" fill="black"/>
                                    <path d="M1045.74 97.214H981.806L884.655 193.365V0H835.05V326.114H884.655V223.318L990.491 326.114H1054.87L932.559 205.948L1045.74 97.214Z" fill="black"/>
                                    <path d="M1206.37 92.5172C1191.46 91.9908 1176.67 95.3379 1163.48 102.225C1150.28 109.113 1139.14 119.301 1131.16 131.775V97.4798H1081.64V326.38H1131.16V207.721C1131.16 163.412 1152.74 139.396 1191.87 139.396C1224.55 139.396 1240.4 156.499 1240.4 191.681V326.292H1290V182.021C1290 125.838 1258.75 92.5172 1206.37 92.5172Z" fill="black"/>
                                    <path d="M1447.59 92.5173C1415.6 92.5173 1384.93 105.094 1362.31 127.479C1339.69 149.865 1326.98 180.227 1326.98 211.886C1326.98 243.544 1339.69 273.906 1362.31 296.292C1384.93 318.678 1415.6 331.254 1447.59 331.254C1479.58 331.254 1510.26 318.678 1532.88 296.292C1555.5 273.906 1568.2 243.544 1568.2 211.886C1568.2 180.227 1555.5 149.865 1532.88 127.479C1510.26 105.094 1479.58 92.5173 1447.59 92.5173ZM1517.34 211.886C1517.34 253.802 1487.97 284.287 1447.59 284.287C1407.21 284.287 1377.75 253.802 1377.75 211.886C1377.42 202.606 1378.97 193.355 1382.33 184.684C1385.68 176.013 1390.77 168.1 1397.29 161.417C1403.8 154.735 1411.61 149.42 1420.24 145.79C1428.88 142.16 1438.17 140.289 1447.55 140.289C1456.93 140.289 1466.22 142.16 1474.85 145.79C1483.49 149.42 1491.29 154.735 1497.81 161.417C1504.32 168.1 1509.41 176.013 1512.77 184.684C1516.12 193.355 1517.68 202.606 1517.34 211.886Z" fill="black"/>
                                    <path d="M1742 143.384V97.214H1672.34V-0.265869H1622.82V97.214H1578.05V143.384H1622.82V237.94C1622.82 296.25 1654.52 330.988 1707.71 330.988C1718.94 330.909 1730.13 329.481 1741.02 326.735H1742V281.362L1740.39 281.805C1732.43 284.05 1724.22 285.271 1715.94 285.439C1687.47 285.439 1672.34 268.247 1672.34 235.635V143.384H1742Z" fill="black"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_616_145">
                                    <rect width="1742" height="429" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                
                                <DelayHovering duration={4} delay={0} className="symbol symbol-01">
                                    <img src={process.env.PUBLIC_URL + "/image/img_symbol_01.webp"} />
                                </DelayHovering>
                                
                                <DelayHovering duration={3} delay={0.1} className="symbol symbol-02">
                                    <img src={process.env.PUBLIC_URL + "/image/img_symbol_02.webp"} />
                                </DelayHovering>

                                <DelayHovering duration={5} delay={0.2} className="symbol symbol-03">
                                    <img src={process.env.PUBLIC_URL + "/image/img_symbol_03.webp"} />
                                </DelayHovering>

                                <DelayHovering duration={4} delay={0.3} className="symbol symbol-04">
                                    <img src={process.env.PUBLIC_URL + "/image/img_symbol_04.webp"} />
                                </DelayHovering>
                            </div>
                        </section>

                        <section className="scroll-section section-03" ref={sectionEndRef}> 
                            <div className="text-cont-wrap">
                                <div className="text-container introduce-msg en">
                                    <Typography className="right" txt="we are professionals"/>
                                    <Typography txt="who can do service planning"/>
                                    <Typography txt="ui/ux design, hybrid app,"/>
                                    <Typography className="right" txt="& native app"/>
                                </div>
                                <div className="text-container introduce-msg ko">
                                    <Typography txt="앱노트는 서비스 기획, UI/UX 디자인,"/>
                                    <Typography txt="하이브리드는 물론 네이티브 앱까지 모두 개발할 수 있는"/>
                                    <Typography txt="국내 몇 안되는 전문가 그룹입니다."/>
                                </div>
                            </div>

                            <div className="card-container">
                                <motion.div className="card card-01" animate={controls} initial="hidden" variants={{
                                    hidden: {
                                        y: "100%",
                                        rotate: -10,
                                    },
                                    visible: {
                                        y: 0,
                                        rotate: -14.56,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 1
                                        }
                                    }
                                }}>
                                    <img src={process.env.PUBLIC_URL + "/image/img_about_card_01.webp"} alt="" />
                                </motion.div>
                                <motion.div className="card card-02" animate={controls} initial="hidden" variants={{
                                    hidden: {
                                        y: "100%",
                                        rotate: -10,
                                    },
                                    visible: {
                                        y: 0,
                                        rotate: 21.57,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 1,
                                        }}
                                    }}>
                                    <img src={process.env.PUBLIC_URL + "/image/img_about_card_02.webp"} alt="" />
                                </motion.div>
                                <motion.div className="card card-03" animate={controls} initial="hidden" variants={{
                                    hidden: {
                                        y: "-100%",
                                        rotate: -10,
                                    },
                                    visible: {
                                        y: 0,
                                        rotate: -9.42,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 1
                                        }
                                    }
                                }} >
                                    <img src={process.env.PUBLIC_URL + "/image/img_about_card_03.webp"} alt="" />
                                </motion.div>
                                <motion.div className="card card-04" animate={controls} initial="hidden" variants={{
                                    hidden: {
                                        x: "100%",
                                        rotate: -10,
                                    },
                                    visible: {
                                        x: 0,
                                        rotate: 17.22,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 1
                                        }
                                    }
                                }} >
                                    <img src={process.env.PUBLIC_URL + "/image/img_about_card_04.webp"} alt="" />
                                </motion.div>
                            </div>
                        </section>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

function Tag({text}) {
    const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
    const ref = useRef();

    return (
        <div className="tag-wrap" 
        ref={ref}
        style={{
            transform: `translate(${mousePosition.left}px,${mousePosition.top}px) rotate(12.81deg) scale(1)`
        }}>
            <div className="tag">
                {text}
            </div>
        </div>
    )
}