import { motion } from 'framer-motion';

export default function SolutionIntroduceIntro(props) {
    return (
        <div className="SolutionIntroduceIntro">
            <div className="num">{props.num}</div>
            <div className="text">{props.msg}</div>
            <motion.strong viewport={{ once: true, amount: "all" }} whileInView="visible" initial="hidden" animate="rest">
                {String(props.name).split("").map((el, idx) => {
                    return(<motion.div key={idx}>
                        <motion.span
                            variants={{
                                hidden: { 
                                    opacity: 0,
                                    y: "50%",
                                    transition: {
                                        duration: 0.4,
                                        delay: 0
                                    }
                                },
                                visible: { 
                                    opacity: 1,
                                    y: "0%",
                                    transition: {
                                        duration: 0.5, 
                                        delay: idx * 0.05,
                                        ease: "easeInOut"
                                    },
                                }
                            }}>
                            {el}
                        </motion.span>
                    </motion.div>)
                })}
            </motion.strong>
            {props.children}
        </div>
    )
}