import { MatterJs } from "./MatterJs";
import Typography from "./Typography";

export default function BlockPage() {
  return (
    <div className="BlockPage">
      <section className="matter">
        <MatterJs gravity={1} background="#000"/>

        <div className="text-container"> 
          <div className="en">
            <Typography txt="Check out" className="right"/>
            <Typography txt="The our website"/>
            <Typography txt="on your pc" className="right"/>
          </div>
          <div className="ko">
            <Typography txt="PC에서 앱노트 웹사이트를"/>
            <Typography txt="살펴보세요!"/>
          </div>
        </div>
      </section>
    </div>
  )
}