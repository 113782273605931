import { GnbContext } from '../Pc';
import { useEffect, useContext } from 'react';
import { motion } from 'framer-motion';

export default function MetaPassport() {
    const gnbContext = useContext(GnbContext);

    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="MetaPassport">
            <div className="work-detail-wrap" data-hash="metaPassport">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>

                    <div className="cover-text-wrap">
                        <div className="container">
                            <h2 className="project-type">SM Brand Marketing</h2>
                            <h4 className="project-name">META PASSPORT</h4>
                            <ul className="project-team">
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    메타패스포트는 지난 2012년 8월 ‘뮤직 네이션 SM타운 (MUSIC NATION
                                    SMTOWN)’ 탄생을 선포, 전 세계 30여 개국의 팬들과 시민권자들에게
                                    실물로 배포했던 ‘뮤직 네이션 SM타운 패스포트’가 전환되어 현실
                                    세계와 디지털 세계를연결하고 모든 정보가 투명하게 기록되는
                                    형태로 새롭게 모바일 어플리케이션으로 서비스를 제공합니다.
                                    <br />
                                    <br />
                                    MUSIC NATION SMTOWN META-PASSPORT는 SM 그룹의 온/오프라인
                                    이벤트마다 제공되는 STAMP를 비롯하여 팬클럽 가입 정보와 MUISC
                                    NATION SMTOWN의 시민권자로서 활동한 정보 등을 더욱 편리하게
                                    수집하고 즐길 수 있는기능을 제공합니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>

                <div className="project-contents dark">
                    <section className="cont-sec-01">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.img
                                variants={variants}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_mpp_01_mobile.webp 296w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_mpp_01.webp 1564w')
                                }
                                src={process.env.PUBLIC_URL + '/site/res/image/img_mpp_01.webp'}
                                sizes="(max-width: 360px) 296px,
                                1564px"
                            />
                        </motion.div>
                    </section>

                    <section className="cont-sec-02">
                        <div className="container">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-01 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="animation-container reactive"></motion.div>
                            </motion.div>
                            <div className="cont-wrap-01 d-flex stretch">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-02 fade-up">
                                    <motion.div
                                        variants={variants}
                                        className="animation-container reactive"></motion.div>
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-03 fade-up">
                                    <motion.div
                                        variants={variants}
                                        className="animation-container reactive"></motion.div>
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <section className="cont-sec-03">
                        <div className="container">
                            <div className="cont-wrap-01">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-01 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_01_mobile.webp 332w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_01.webp 887w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_section_02_01.webp'
                                        }
                                        sizes="(max-width: 767px) 332px,887px"
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-02 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_02_mobile.webp 332w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_02.webp 887w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_section_02_02.webp'
                                        }
                                        sizes="(max-width: 767px) 332px,887px"
                                    />
                                </motion.div>
                            </div>
                            <div className="cont-wrap-02">
                                <motion.div className="cont-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_03_mobile.webp 332w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_03.webp 887w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_section_02_03.webp'
                                        }
                                        sizes="(max-width: 767px) 332px,887px"
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-04 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_04_mobile.webp 332w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_02_04.webp 887w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_section_02_04.webp'
                                        }
                                        sizes="(max-width: 767px) 332px,887px"
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <section className="cont-sec-04">
                        <div className="container">
                            <div className="cont-wrap-01 d-flex stretch">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-01 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_03_01_mobile.webp 332w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_03_01.webp 887w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_section_03_01.webp'
                                        }
                                        sizes="(max-width: 767px) 332px,887px"
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-02 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_03_02_mobile.webp 332w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_section_03_02.webp 887w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_section_03_02.webp'
                                        }
                                        sizes="(max-width: 767px) 332px,887px"
                                    />
                                </motion.div>
                            </div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-03 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="animation-container reactive"></motion.div>
                            </motion.div>
                        </div>
                    </section>

                    <section className="cont-sec-05">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.div
                                variants={variants}
                                className="animation-container reactive"></motion.div>
                        </motion.div>

                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="img-slide fade-up">
                            <motion.ul variants={variants}>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_01_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_01.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_01.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_02_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_02.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_02.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_03_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_03.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_03.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_04_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_04.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_04.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_05_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_05.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_05.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_06_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_06.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_06.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_07_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_07.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_07.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_08_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_08.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_08.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_09_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_09.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_09.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_10_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_10.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_10.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_11_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_11.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_11.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_12_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_12.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_12.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                            </motion.ul>
                            <motion.ul variants={variants}>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_01_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_01.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_01.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_02_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_02.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_02.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_03_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_03.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_03.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_04_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_04.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_04.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_05_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_05.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_05.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_06_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_06.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_06.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_07_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_07.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_07.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_08_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_08.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_08.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_09_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_09.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_09.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_10_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_10.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_10.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_11_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_11.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_11.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_12_mobile.webp 130w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_mpp_fanclub_12.webp 327w')
                                        }
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_mpp_fanclub_12.webp'
                                        }
                                        sizes="(max-width: 767px) 130px, 327px"
                                    />
                                </li>
                            </motion.ul>
                        </motion.div>

                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="container d-flex stretch fade-up">
                            <motion.div variants={variants} className="cont-wrap-01">
                                <div className="cont-img-02">
                                    <div className="animation-container reactive"></div>
                                </div>
                                <div className="cont-img-03">
                                    <div className="animation-container reactive"></div>
                                </div>
                            </motion.div>
                            <motion.div variants={variants} className="cont-wrap-02">
                                <div className="cont-img-04">
                                    <div className="animation-container reactive"></div>
                                </div>
                                <div className="cont-img-05">
                                    <div className="animation-container reactive"></div>
                                </div>
                                <div className="cont-img-06">
                                    <div className="animation-container reactive"></div>
                                </div>
                            </motion.div>
                            <motion.div variants={variants} className="cont-wrap-03">
                                <div className="cont-img-07">
                                    <div className="animation-container reactive"></div>
                                </div>
                                <div className="cont-img-08">
                                    <div className="animation-container reactive"></div>
                                </div>
                            </motion.div>
                        </motion.div>

                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-09 fade-up">
                            <motion.img
                                variants={variants}
                                src={process.env.PUBLIC_URL + '/site/res/image/img_mpp_logo.webp'}
                                alt="MUSIC NATION SMTOWN META-PASSPORT"
                            />
                        </motion.div>
                    </section>
                </div>
            </div>
        </div>
    );
}
