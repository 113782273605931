import React, { forwardRef } from 'react'

export default forwardRef(function TextInput( props, ref ) {
    return (
        <div className="input-box">
            <input
                type="text"
                name={props.name}
                placeholder={props.placeholder}
                className={props.className}
                ref={props.getRef(props.name)}
            />
        </div>
    )
}) 
