import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { motion } from 'framer-motion';
import { GnbContext } from '../Pc';
import { useEffect, useContext } from 'react';

export default function Toyota() {
    const gnbContext = useContext(GnbContext);
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    useEffect(() => {
        gnbContext.actions.setGnbColor('white');
    }, []);
    return (
        <div className="Toyota">
            <div className="work-detail-wrap work-detail-container" data-hash="toyota">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>
                    <div className="cover-text-wrap dark">
                        <div className="container">
                            <h2 className="project-type">TOYOTA Korea</h2>
                            <h4 className="project-name">
                                Showroom
                                <br />& Workshop
                            </h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                className="project-desc"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.p variants={variants}>
                                    토요타와 렉서스에서 차량 판매나 수리를 하는 현장 직원들을 위한
                                    애플리케이션 서비스입니다. 자동차 판매에 있어 고객 정보 수집과
                                    계약에 도움을 주는 스마트 쇼룸과 서비스 센터 운영에 편리함을
                                    제공하는 스마트 워크샵 그리고 차량 수리 시 정비사를 위한 스마트
                                    테크 앱 이렇게 세 가지로 구성되어 있습니다.
                                    <br />
                                    <br />
                                    움직임이 많은 사용자들의 업무 특성상, 기존의 불편한 데스크톱 PC
                                    환경에서 벗어나 태블릿이나 스마트폰에서 업무를 처리할 수 있도록
                                    전환하였습니다. 모바일 디바이스로 전환되는 과정에서 실사용자들이
                                    빠르게 적용할 수 있도록 기존 업무 프로세스를 자연스러운 UI/UX로
                                    녹여 편리함을 제공하였습니다.
                                    <br />
                                    <br />
                                    초기 오픈 후 후속으로 진행된 고도화 프로젝트에서는 차량 시승부터
                                    결제, 정비/점검, 중고차 판매, 재구매까지 Customer Journey의 모든
                                    구간에서 적극적으로 서비스가 활용될 수 있도록 기능을
                                    강화하였습니다. 모바일 기기를 활용한 텍스트, 이미지, 동영상 등의
                                    디지털 데이터 수집으로 고객 정보 공유 및 전달이 간단해졌고
                                    원활한 커뮤니케이션이 가능해졌습니다.
                                    <br />
                                    <br />
                                    또한 고객과 대면이 어려운 환경 속에서도 비대면으로 고객의 정보를
                                    수집하고 비대면 계약과 결제 그리고 차량 정기점검 및 수리 등
                                    차량에 관한 라이프 사이클을 서비스에 담았습니다. 이처럼
                                    실무자들의 모든 업무 과정이 유기적인 상호 관계를 이루어 보다
                                    나은 서비스를 제공할 수 있도록 구성했습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className="work-detail-contents dark">
                    <section className="work-detail-information">
                        <div className="work-detail-information-container work-detail-information-container-01">
                            <div className="work-detail-information-img work-detail-information-img-01 fade-up">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section01_01.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </div>

                            <motion.h5
                                className="work-detail-information-title font-en fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.div className="animation-container" variants={variants}>
                                    TOYOTA ∙ LEXUS
                                    <br />
                                    Showroom
                                </motion.div>
                            </motion.h5>

                            <div className="work-detail-information-img work-detail-information-img-02">
                                <motion.ul
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}>
                                    <motion.li className="fade-up" variants={variants}>
                                        <video
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/video/aweb_toyota_01.mp4'
                                            }
                                            muted
                                            autoPlay
                                            playsInline
                                            poster={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/aweb_toyota_01.webp'
                                            }></video>

                                        {/* <img src={process.env.PUBLIC_URL +'/site/res/image/aweb_toyota_01.webp'} alt="토요타 이미지" />  */}
                                    </motion.li>
                                    <motion.li className="fade-up" variants={variants}>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section01_02.webp'
                                            }
                                            alt="토요타 이미지"
                                        />
                                    </motion.li>
                                </motion.ul>
                            </div>
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-03 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section01_03.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-04 fade-up"
                                data-delay="10">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section01_04.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-05 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section01_05.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-06 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section01_06.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-07 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section01_07.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-08 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <ul>
                                    <motion.li variants={variants}>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section01_08.webp'
                                            }
                                            alt="토요타 이미지"
                                        />
                                    </motion.li>
                                    <motion.li variants={variants}>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section01_09.webp'
                                            }
                                            alt="토요타 이미지"
                                        />
                                    </motion.li>
                                </ul>
                            </motion.div>
                        </div>

                        <motion.div
                            className="work-detail-information-container work-detail-information-container-02"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}>
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-01 fade-up"
                                variants={variants}>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section02_01.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.h5
                                className="work-detail-information-title font-en fade-up"
                                variants={variants}>
                                <div className="animation-container">
                                    TOYOTA ∙ LEXUS
                                    <br />
                                    Workshop
                                </div>
                            </motion.h5>

                            <motion.div
                                className="work-detail-information-img work-detail-information-img-02 fade-up"
                                variants={variants}>
                                <video
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/video/aweb_toyota_02.mp4" muted autoPlay playsInline poster="../res/image/img_toyota_video_02.webp'
                                    }></video>

                                {/* <img src={process.env.PUBLIC_URL +'/site/res/image/img_toyota_video_02.webp'} alt="토요타 이미지" />  */}
                            </motion.div>
                        </motion.div>

                        <motion.div
                            className="work-detail-information-container work-detail-information-container-03 fade-up"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}>
                            <div className="work-detail-information-bg reactive-rectangle"></div>

                            <motion.div
                                className="work-detail-information-img work-detail-information-img-01 swiper-container"
                                variants={variants}>
                                <Swiper
                                    loop={true}
                                    autoplay={{ delay: 1, disableOnInteraction: false }}
                                    speed={4000}
                                    modules={[Autoplay]}
                                    slidesPerView={'auto'}
                                    freeMode={true}
                                    centeredSlides={false}>
                                    <SwiperSlide>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section03_01.webp'
                                            }
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section03_02.webp'
                                            }
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section03_03.webp'
                                            }
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section03_04.webp'
                                            }
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section03_05.webp'
                                            }
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/site/res/image/img_toyota_section03_06.webp'
                                            }
                                        />
                                    </SwiperSlide>
                                </Swiper>
                            </motion.div>
                        </motion.div>

                        <div className="work-detail-information-container work-detail-information-container-04">
                            <motion.div
                                className="work-detail-information-img work-detail-information-img-01 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section04_01.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-02 fade-up">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section04_02.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="work-detail-information-img work-detail-information-img-03 fade-up"
                                data-delay="5">
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section04_03.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.div
                                className="work-detail-information-img work-detail-information-img-04 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section04_04.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.div
                                className="work-detail-information-img work-detail-information-img-05 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section04_05.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.div
                                className="work-detail-information-img work-detail-information-img-06 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.img
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_section04_06.webp'
                                    }
                                    alt="토요타 이미지"
                                />
                            </motion.div>

                            <motion.div
                                className="work-detail-information-img work-detail-information-img-07 fade-up"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}>
                                <motion.video
                                    variants={variants}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/video/aweb_toyota_03.mp4'
                                    }
                                    muted
                                    autoPlay
                                    playsInline
                                    poster={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_video_03.webp'
                                    }></motion.video>

                                {/* <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_toyota_video_03.webp'
                                    }
                                    alt="토요타 이미지"
                                /> */}
                            </motion.div>

                            <h5 className="work-detail-information-title font-en">
                                <div className="animation-container">
                                    TOYOTA
                                    <br />
                                    Showroom & Workshop
                                </div>
                            </h5>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
