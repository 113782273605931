import '../styles/layout.scss';
import MainSectionIntro from '../components/Main/MainSectionIntro';
import MainIntroText from '../components/Main/MainIntroText';
import MainSectionAbout from '../components/Main/MainSectionAbout';
import MainSectionWork from '../components/Main/MainSectionWork';
import MainSectionInsight from '../components/Main/MainSectionInsight';
import MainSectionRecruit from '../components/Main/MainSectionRecruit';
import MainAwardMark from '../components/Main/MainAwardMark';
import React, { useContext, useEffect } from 'react';
import { GnbContext } from '../components/Pc';

export default function MainPage() {
    const gnbContext = useContext(GnbContext);
    useEffect(() => {
        gnbContext.actions.setGnbColor("black");
    },[]);

    return (
        <div className="MainPage">
            <MainSectionIntro />
            <MainIntroText />
            <MainSectionAbout />
            <MainSectionWork />
            <MainSectionInsight />
            <MainSectionRecruit />
            <MainAwardMark />
        </div>    
    );
}
