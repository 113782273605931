import SolutionInfoIntro from "./SolutionInfoIntro";
import SolutionIntroduceIntro from './SolutionIntroduceIntro';

export default function SolutionSectionMentor() {
    const textArr = [
        "Contents".split(""), 
        "Library".split(""),
        "Solution".split("")
    ];
    return (
    <div className="SolutionSectionMentor">
        <div className="container">
            <div className="sticky">
                <div className="bg">
                    <img src={process.env.PUBLIC_URL + '/image/img_bg_solution_mentor.webp'} />
                </div>
            </div>
            <SolutionIntroduceIntro num="03" msg="쉽고 빠른 콘텐츠 라이브러리 솔루션" name="Mentor"/>
        </div>
        <div className="contents">
            <SolutionInfoIntro text={textArr} img={process.env.PUBLIC_URL + '/image/img_solution_mentor.webp'} />
            <div className="solution-introduction">
                <div className="simple-text">
                    누구나 쉽고 빠르게 
                    <br/>디지털 콘텐츠를 관리할 수 있는 
                    <br/>콘텐츠 라이브러리 솔루션입니다.
                </div>

                <div className="detail-text">
                    <p>
                        콘텐츠를 중앙에서 관리・배포하는 방식으로, <span className="en">PDF</span>와 <span className="en">Video</span> 콘텐츠를 빠르고 신속하게 
                        다양한 디바이스에 제공할 수 있습니다. 영업 담당자는 실시간으로 업데이트된 자료를 활용할 수 있으며,
                        관리자는 활용되는 데이터 통계를 기반으로 양방향 소통이 가능합니다.
                    </p>
                    <p>
                        단순 콘텐츠 제공 이외에도 게시판을 통해 내부 커뮤니티에서 소통이 가능하며, 
                        퀴즈 응시를 통한 교육 진행, 전자서명을 통한 확인 기록 증빙 등 다양한 부가기능이 제공됩니다. 
                        또한 이 모든 서비스에 대해 외부로 유출되지 않도록 서버부터 개인 디바이스까지 최고 수준의 보안이 제공됩니다. 선택 사항에 따라 파일 공유 및 추출이 차단할 수 있으며 암호화된 형태로 
                        모든 콘텐츠 라이브러리를 관리하여 앱 외부로 유출되지 않도록 서비스합니다.
                    </p>

                    <a href="" className="btn-link">
                        MENTOR 보러가기
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}