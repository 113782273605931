import { useState, useRef, useEffect,useContext } from 'react';
import PopupForm from './PopupForm';
import { ContactContext } from './Mobile';
import { AnimatePresence, motion } from "framer-motion";
export default function ContactPopup() {
    const contactContext = useContext(ContactContext);
    const [showContent, setShowContent] = useState(true);
    const popupRef = useRef();
    const badgeRef = useRef();

    const closeModal = (e) => {
        if (e.target.contains(popupRef.current)) {
            if (contactContext.state.popupState) contactContext.actions.setPopupOn(false);
        } 
    };

    useEffect(() => {
            if (contactContext.state.popupState) {
                setShowContent(true);
    
            } else {
                setTimeout(() => {
                    setShowContent(false);
                },210)
            }
        
        
        window.addEventListener('click', closeModal);
        return () => {
            window.removeEventListener('click', closeModal);
        };
    }, [contactContext.state.popupState]);

    useEffect(() => {
        if (contactContext.state.popupState) {
            document.querySelector("html").classList.add("lock");
        } else {
            document.querySelector("html").classList.remove("lock");
        }
    },[contactContext.state.popupState]);

    return (
        <div className="ContactPopup" onClick={closeModal}>
            <motion.div
                ref={badgeRef}
                className="contact-badge"
                onClick={() => {
                    contactContext.actions.setPopupOn(true);
                }}
                whileHover={{
                    scale: 1.1,
                    transition: {
                        type: 'spring',
                        duration: 0.05,
                        damping: 10,
                        stiffness: 500,
                        restDelta: 0.001
                    }
                }}>
                <div className={`badge-container`}>
                    <svg
                        width="89"
                        height="90"
                        viewBox="0 0 89 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_727_663)">
                            <path
                                d="M1.61996 56.2718L0.911835 53.4102L1.41625 53.2841L1.98858 55.6122L3.66674 55.1951L3.20112 53.2938L3.71524 53.1677L4.18086 55.069L5.99483 54.6228L5.42251 52.2947L5.92692 52.1686L6.63505 55.0302L1.61996 56.2718Z"
                                fill="white"
                            />
                            <path
                                d="M3.26902 48.6764L5.58741 50.1024L5.65531 50.8008L2.85191 49.0742L0.514119 51.2664L0.446217 50.5971L2.36689 48.7831L0.106704 47.4057L0.0388012 46.7364L2.7743 48.3951L5.16059 46.1252L5.2285 46.8237L3.25932 48.6958L3.26902 48.6764Z"
                                fill="white"
                            />
                            <path
                                d="M2.5415 44.8545C1.03794 44.7769 -0.0679027 43.5449 0.00970025 42.0511C0.0776028 40.7027 1.00884 39.8297 1.91097 39.6745L1.88187 40.2565C1.18344 40.4505 0.582022 41.1296 0.54322 42.0802C0.485018 43.283 1.37745 44.2337 2.5803 44.2919C3.78314 44.3501 4.77258 43.4867 4.83078 42.2936C4.87928 41.3527 4.35546 40.6057 3.67644 40.3535L3.70554 39.7715C4.58827 40.0237 5.4225 40.984 5.3546 42.3227C5.277 43.8166 4.05475 44.9321 2.5512 44.8545H2.5415Z"
                                fill="white"
                            />
                            <path
                                d="M3.71524 37.5695L0.620823 36.9002L0.737227 36.357L3.85104 37.036C4.82108 37.2494 5.49041 36.8032 5.67471 35.9593C5.85902 35.1154 5.44191 34.4363 4.46217 34.2229L1.34835 33.5439L1.46475 33.0007L4.55917 33.67C5.82022 33.9416 6.43134 34.9116 6.17913 36.066C5.92692 37.2203 4.97629 37.8509 3.71524 37.5695Z"
                                fill="white"
                            />
                            <path
                                d="M5.85902 31.7687L6.05303 31.2546C6.64475 31.4098 7.11037 30.9345 7.28498 30.4689C7.48868 29.9256 7.35288 29.363 6.85816 29.1787C6.43134 29.0235 6.05303 29.1787 5.71352 29.5473L5.1703 30.1584C4.59797 30.7987 4.08385 30.9733 3.42423 30.7211C2.6579 30.4398 2.43479 29.654 2.7355 28.8392C3.09442 27.8789 3.91895 27.6849 4.54947 27.8401L4.35546 28.3542C3.76374 28.2766 3.38543 28.6064 3.22052 29.0429C3.03621 29.5376 3.16232 30.0323 3.60854 30.1972C4.05475 30.3622 4.34576 30.2069 4.72408 29.7898L5.2091 29.2369C5.76202 28.6161 6.31494 28.3639 7.03277 28.6355C7.8476 28.9362 8.09981 29.7607 7.76999 30.6241C7.40138 31.5941 6.57685 32.0015 5.84932 31.7493L5.85902 31.7687Z"
                                fill="white"
                            />
                            <path
                                d="M4.54947 24.8233L5.92693 22.2139L6.39254 22.4564L5.2673 24.5711L6.79026 25.3762L7.71179 23.6495L8.17741 23.892L7.25588 25.6187L8.90494 26.4917L10.0302 24.377L10.4958 24.6196L9.11835 27.229L4.54947 24.8039V24.8233Z"
                                fill="white"
                            />
                            <path
                                d="M10.3794 17.3734L13.5514 20.138L13.1828 20.5551L9.29295 17.16L9.62277 16.772L12.9306 17.0145L12.2322 13.7648L12.5717 13.3768L16.4615 16.772L16.1026 17.1891L12.9306 14.4245L13.6096 17.5965L10.3697 17.3637L10.3794 17.3734Z"
                                fill="white"
                            />
                            <path
                                d="M14.9095 11.2233L17.1599 9.33173L17.4995 9.72944L15.6661 11.2718L16.7719 12.591L18.2658 11.33L18.6053 11.7277L17.1114 12.9888L18.3143 14.4244L20.1477 12.8821L20.4872 13.2798L18.2367 15.1713L14.9192 11.2136L14.9095 11.2233Z"
                                fill="white"
                            />
                            <path
                                d="M20.4872 9.54514L19.3037 7.79907L19.7208 7.51776L20.9043 9.26383L21.6706 10.5734L21.4087 10.7577L20.4775 9.55484L20.4872 9.54514ZM21.7773 11.8441C21.6318 11.6307 21.6803 11.3203 21.9034 11.1748C22.1265 11.0196 22.4272 11.0875 22.5728 11.3106C22.7183 11.524 22.6698 11.8344 22.4466 11.9799C22.2235 12.1254 21.9228 12.0575 21.7773 11.8441Z"
                                fill="white"
                            />
                            <path
                                d="M26.385 3.85106L26.7924 3.67645L30.983 7.479L30.4495 7.7118L29.1496 6.52836L26.8894 7.5178V9.27356L26.3656 9.49667L26.3947 3.84136L26.385 3.85106ZM28.7228 6.15005L26.8991 4.48158V6.95518L28.7228 6.15005Z"
                                fill="white"
                            />
                            <path
                                d="M31.9821 2.34748L30.3719 2.81309L30.2263 2.30867L33.9707 1.23193L34.1162 1.73635L32.5059 2.20197L33.7864 6.66414L33.2626 6.81934L31.9821 2.35718V2.34748Z"
                                fill="white"
                            />
                            <path
                                d="M37.5598 1.09613L35.9108 1.34834L35.8332 0.834219L39.6745 0.252197L39.7521 0.766317L38.0933 1.01853L38.7918 5.6068L38.2485 5.6844L37.5501 1.09613H37.5598Z"
                                fill="white"
                            />
                            <path
                                d="M42.1481 0.0679026L45.097 0V0.52382L42.7107 0.582022L42.7495 2.30869L44.709 2.26019V2.78401L42.7689 2.83251L42.8077 4.70468L45.2037 4.64648V5.1703L42.2645 5.2382L42.1481 0.0679026Z"
                                fill="white"
                            />
                            <path
                                d="M51.0821 5.73291L48.3466 1.12524L47.8131 5.31579L47.2602 5.24789L47.9198 0.126099L48.4048 0.184301L51.1403 4.79198L51.6739 0.601417L52.2268 0.669319L51.5672 5.79111L51.0821 5.73291Z"
                                fill="white"
                            />
                            <path
                                d="M56.2136 2.16316L54.6034 1.70724L54.7489 1.20282L58.4932 2.26986L58.3477 2.77428L56.7375 2.31836L55.457 6.78053L54.9332 6.63503L56.2136 2.17286V2.16316Z"
                                fill="white"
                            />
                            <path
                                d="M60.7243 3.13324L61.2384 3.33695L59.3177 8.12893L58.8036 7.92522L60.734 3.13324H60.7243Z"
                                fill="white"
                            />
                            <path
                                d="M62.4801 6.62536C63.2173 5.31581 64.8179 4.87929 66.1177 5.60682C67.4176 6.33435 67.8735 7.93491 67.1362 9.24446C66.399 10.554 64.7985 10.9905 63.4986 10.263C62.1988 9.53547 61.7428 7.93491 62.4801 6.62536ZM66.6512 8.97285C67.243 7.91551 66.9131 6.65446 65.8655 6.06274C64.8179 5.47102 63.5665 5.84933 62.9748 6.89697C62.3831 7.95431 62.7129 9.21536 63.7605 9.80708C64.8082 10.3988 66.0595 10.0205 66.6512 8.97285Z"
                                fill="white"
                            />
                            <path
                                d="M70.4732 15.0938L70.6381 9.73916L67.9414 12.9888L67.5145 12.6396L70.8127 8.67212L71.191 8.98253L71.0261 14.3371L73.7228 11.0875L74.1496 11.4367L70.8515 15.4042L70.4732 15.0938Z"
                                fill="white"
                            />
                            <path
                                d="M71.7536 17.742L71.5014 17.4801L72.5684 17.1212C72.5296 16.9466 72.5684 16.7623 72.7042 16.6362C72.8983 16.4518 73.199 16.4518 73.393 16.6362C73.5773 16.8302 73.5579 17.1406 73.3736 17.3152C73.296 17.3928 73.199 17.4316 73.1117 17.451L71.773 17.7323L71.7536 17.742Z"
                                fill="white"
                            />
                            <path
                                d="M78.7088 21.6512L78.1656 20.8364L76.5456 21.9131L76.2352 21.4572L80.5324 18.5859L81.3861 19.8663C81.9875 20.7685 81.7838 21.7191 81.0175 22.2235C80.2608 22.7279 79.3102 22.5533 78.6991 21.6512H78.7088ZM80.9593 20.1476L80.416 19.3328L78.5924 20.5454L79.1356 21.3602C79.5721 22.0101 80.1929 22.0974 80.7071 21.7482C81.2309 21.399 81.3861 20.7976 80.9496 20.1379L80.9593 20.1476Z"
                                fill="white"
                            />
                            <path
                                d="M83.6366 23.5913L83.8791 24.086L79.7176 26.1425L80.8623 28.4609L80.3966 28.6937L79.0095 25.8806L83.6463 23.5913H83.6366Z"
                                fill="white"
                            />
                            <path
                                d="M85.955 28.7325L86.9153 31.5165L86.4206 31.6911L85.6445 29.4309L84.0149 29.9935L84.6551 31.8463L84.1604 32.0209L83.5202 30.1681L81.7547 30.7793L82.5307 33.0394L82.036 33.2141L81.0757 30.43L85.9647 28.7519L85.955 28.7325Z"
                                fill="white"
                            />
                            <path
                                d="M87.9532 35.8235L88.0405 36.26L83.4038 39.4999L83.2971 38.9276L84.7424 37.9188L84.2768 35.4937L82.5598 35.096L82.4531 34.543L87.9629 35.8235H87.9532ZM85.1983 37.5986L87.2257 36.1824L84.8103 35.6198L85.1886 37.5889L85.1983 37.5986Z"
                                fill="white"
                            />
                            <path
                                d="M84.8006 40.6833L84.8297 41.2363C84.2186 41.3236 83.9858 41.9541 84.0052 42.4488C84.0343 43.0308 84.3738 43.4867 84.9073 43.4576C85.3535 43.4382 85.6445 43.1472 85.8094 42.6622L86.0617 41.8862C86.3236 41.0713 86.731 40.7124 87.4391 40.6736C88.2539 40.6348 88.7681 41.2654 88.8166 42.1287C88.8651 43.1569 88.1957 43.6613 87.5555 43.768L87.5264 43.2151C88.089 43.0502 88.3218 42.5943 88.2927 42.1384C88.2636 41.6049 87.9435 41.2072 87.4779 41.2363C87.0026 41.2557 86.7892 41.5176 86.6146 42.0511L86.3915 42.7495C86.1296 43.5352 85.7221 43.9815 84.9655 44.0203C84.1022 44.0591 83.5396 43.4188 83.5008 42.4876C83.4523 41.4594 84.044 40.7512 84.82 40.6833H84.8006Z"
                                fill="white"
                            />
                            <path
                                d="M88.7487 46.5424L88.5255 49.4816L88.0017 49.4428L88.186 47.0565L86.4691 46.9304L86.3236 48.8802L85.7997 48.8414L85.9452 46.8916L84.0828 46.7461L83.8985 49.1324L83.3746 49.0936L83.5978 46.1544L88.7487 46.5424Z"
                                fill="white"
                            />
                            <path
                                d="M83.5008 51.0046C83.7627 51.0531 83.947 51.2956 83.8985 51.5672C83.85 51.8291 83.5881 52.0037 83.3359 51.9552C83.0739 51.9067 82.8896 51.6642 82.9381 51.3926C82.9866 51.1307 83.2486 50.956 83.5008 51.0046ZM86.0423 51.6933L88.1181 52.0716L88.0308 52.576L85.955 52.1977L84.4805 51.8388L84.5387 51.5284L86.0423 51.703V51.6933Z"
                                fill="white"
                            />
                            <path
                                d="M10.1854 66.9228L9.25415 65.5356L9.69067 65.2446L11.8538 68.4748L11.4173 68.7659L10.4861 67.3787L6.62535 69.9493L6.32464 69.4934L10.1854 66.9228Z"
                                fill="white"
                            />
                            <path
                                d="M12.8627 69.7844L13.2313 70.2015L11.5531 71.6954L13.5611 73.9459L15.2393 72.452L15.6079 72.8594L11.7568 76.3031L11.3882 75.886L13.1731 74.2854L11.1651 72.0349L9.38025 73.6355L9.01164 73.228L12.8627 69.7844Z"
                                fill="white"
                            />
                            <path
                                d="M17.2764 74.4115L19.5268 76.3128L19.1873 76.7105L17.3637 75.1681L16.2481 76.4874L17.742 77.7484L17.4025 78.1461L15.9086 76.8851L14.7058 78.311L16.5294 79.8534L16.1899 80.2511L13.9394 78.3498L17.2764 74.4018V74.4115Z"
                                fill="white"
                            />
                            <path
                                d="M23.4652 78.9998L23.9405 79.2811L21.2923 83.7141L20.817 83.4328L23.4652 78.9998Z"
                                fill="white"
                            />
                            <path
                                d="M27.3744 86.7407L26.0843 81.5413L24.3576 85.402L23.8532 85.1789L25.9582 80.4645L26.4044 80.6683L27.6848 85.8676L29.4115 82.0069L29.9159 82.23L27.811 86.9444L27.3647 86.7504L27.3744 86.7407Z"
                                fill="white"
                            />
                            <path
                                d="M31.0121 85.3826C31.3904 83.9276 32.826 83.0933 34.2714 83.4619C35.7168 83.8306 36.5704 85.2565 36.1921 86.7213C36.0175 87.3906 35.6295 87.9241 35.1153 88.2733L35.7265 89.2725L34.9989 89.0881L34.6594 88.5255C34.1356 88.7486 33.5342 88.7971 32.9424 88.6419C31.4971 88.2733 30.6435 86.8474 31.0218 85.3826H31.0121ZM34.3684 88.0599L33.7573 87.0705L34.1938 86.7892L34.8146 87.8077C35.2026 87.5264 35.5033 87.0996 35.6392 86.5758C35.9399 85.402 35.2996 84.2671 34.1356 83.9664C32.9715 83.6656 31.856 84.3447 31.5553 85.5184C31.2546 86.6922 31.8948 87.8271 33.0589 88.1278C33.5148 88.2442 33.9707 88.2151 34.3587 88.0599H34.3684Z"
                                fill="white"
                            />
                            <path
                                d="M38.3649 87.6331L38.656 84.4805L39.2089 84.529L38.9179 87.701C38.8306 88.6904 39.3447 89.3016 40.208 89.3792C41.0714 89.4568 41.6922 88.962 41.7892 87.9629L42.0802 84.7909L42.6331 84.8394L42.3421 87.992C42.2257 89.2725 41.3333 90 40.1595 89.8933C38.9858 89.7866 38.2485 88.9135 38.3649 87.6331Z"
                                fill="white"
                            />
                            <path
                                d="M44.903 84.8394H45.4559L45.5529 89.9902H45L44.903 84.8394Z"
                                fill="white"
                            />
                            <path
                                d="M47.8131 84.723L49.4913 84.5193C50.4322 84.4029 51.0724 84.8782 51.1791 85.7124C51.2761 86.4885 50.8299 87.0802 50.0151 87.2451L52.3335 89.3695L51.6157 89.4568L49.2876 87.3227L48.7055 87.3906L48.9966 89.7769L48.4436 89.8448L47.8131 84.7133V84.723ZM49.695 86.7601C50.3449 86.6825 50.6844 86.343 50.6165 85.7706C50.5486 85.1983 50.1315 84.9461 49.4816 85.0334L48.4145 85.1595L48.6279 86.8862L49.6853 86.7601H49.695Z"
                                fill="white"
                            />
                            <path
                                d="M55.1175 86.469L52.3917 84.1021L53.0416 83.9275L55.2436 85.8385L56.2233 83.0933L56.8733 82.9187L55.651 86.3235L56.2039 88.4382L55.6801 88.574L55.1272 86.4496L55.1175 86.469Z"
                                fill="white"
                            />
                            <path
                                d="M60.8407 81.3473L61.3451 81.1241L63.4695 85.8288L62.9651 86.0616L60.831 81.3473H60.8407Z"
                                fill="white"
                            />
                            <path
                                d="M65.0604 83.6172L65.5357 83.3455C65.8946 83.85 66.5639 83.7724 66.9907 83.5299C67.4951 83.2388 67.7474 82.7247 67.4855 82.2688C67.2623 81.8808 66.8743 81.7547 66.3699 81.8323L65.5648 81.9584C64.7208 82.0942 64.2067 81.9002 63.8575 81.2891C63.4501 80.5809 63.7799 79.834 64.5268 79.4072C65.4193 78.8931 66.1759 79.2617 66.5542 79.7952L66.0789 80.0668C65.6715 79.64 65.1671 79.64 64.7693 79.8728C64.3037 80.1347 64.1 80.6003 64.3328 81.0175C64.5656 81.4249 64.8955 81.5025 65.4484 81.4152L66.1759 81.2988C66.9907 81.1727 67.5825 81.3279 67.9608 81.9972C68.3876 82.7441 68.0675 83.5396 67.2623 83.9955C66.3699 84.5096 65.4678 84.2962 65.0604 83.6366V83.6172Z"
                                fill="white"
                            />
                            <path
                                d="M69.8427 75.9151L70.2501 75.5464L71.7536 77.2246L74.0041 75.2069L72.5103 73.5288L72.9177 73.1602L76.3613 77.0112L75.9539 77.3798L74.363 75.595L72.1125 77.6126L73.7034 79.3975L73.296 79.7661L69.8524 75.9151H69.8427Z"
                                fill="white"
                            />
                            <path
                                d="M74.4503 71.4917L76.3516 69.2412L76.7493 69.5807L75.207 71.4141L76.5262 72.5199L77.7873 71.0261L78.185 71.3656L76.9239 72.8595L78.3499 74.0623L79.8922 72.2289L80.2899 72.5684L78.3887 74.8189L74.4406 71.4917H74.4503Z"
                                fill="white"
                            />
                            <path
                                d="M77.5253 67.6309L78.4469 66.2244C78.9707 65.4387 79.737 65.2446 80.4451 65.7103C81.1048 66.1371 81.2697 66.8549 80.8623 67.5921L83.9761 67.2041L83.5784 67.8055L80.4549 68.1936L80.1347 68.6883L82.1427 70.0075L81.842 70.4732L77.5253 67.6406V67.6309ZM80.2996 67.5145C80.6586 66.9713 80.6198 66.496 80.1444 66.1759C79.6594 65.8558 79.2035 66.011 78.8446 66.5639L78.2626 67.4563L79.7176 68.407L80.3093 67.5145H80.2996Z"
                                fill="white"
                            />
                            <path
                                d="M80.319 63.033L81.5995 60.3751L82.0748 60.5982L81.0369 62.7517L82.5889 63.4986L83.4329 61.7331L83.9082 61.9562L83.0642 63.7217L84.7424 64.5365L85.7803 62.383L86.2557 62.6061L84.9752 65.264L80.3287 63.0233L80.319 63.033Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_727_663">
                                <rect width="88.8166" height="90" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className="badge-emoji"></div>
                </div>
            </motion.div>
            <AnimatePresence>
                {contactContext.state.popupState && (
                    <motion.div
                        ref={popupRef}
                        className={`contact-popup`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <div className="popup-container">
                            <div className="popup-box box-right">
                                <div className="popup-header">
                                    <button
                                        className="close-btn"
                                        onClick={() => {
                                            contactContext.actions.setPopupOn(false);
                                        }}></button>
                                    <p>
                                        let’s work together{' '}
                                        <img src={'image/img_icon_rock.webp'}></img>
                                    </p>
                                </div>
                                {showContent == true ? <PopupForm /> : null}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
