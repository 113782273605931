import { animate, motion } from "framer-motion"
import { useContext } from "react";
import { TransitionContext } from "./Pc";

export default function Transition(props) {
    const duration = 0.3;
    const delay = 0.05;
    const transitionContext = useContext(TransitionContext);
    return (
        !transitionContext.state.complete &&
        <div className="Transition">
            <motion.div className="dim"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{
                duration: 0.3, 
                ease: "easeInOut"
            }}/>
            {Array(5).fill(0).map((val, idx) => {
                return (
                    <motion.div className="bar" key={idx}
                        initial={{ y: "-100%" }}
                        animate={{ y: "0" }}
                        transition={{
                            duration: duration, 
                            delay: idx * delay,
                            ease: "easeInOut"
                        }}
                        onAnimationComplete={() => {
                            if (idx == 4) {
                                setTimeout(() => {
                                    const bars = document.querySelectorAll(".bar");
                                    for (let i = 0; i < 5; i++) {
                                        const el = bars[4-i];
                                        animate(el, { y: "100%" }, { 
                                            duration: duration,
                                            delay: i * delay,
                                            ease: "easeInOut"
                                        });

                                        if (i == 4) {
                                            animate(".dim", { opacity: 0 }, { 
                                                duration: 0.5,
                                                onComplete: () => {
                                                    transitionContext.actions.setTransitionComplt(true);
                                                }
                                            });
                                        }
                                    }
                                }, 300);
                            }
                        }}
                    />
                )
            })}
        </div>
    )
}