import { workDetailPath } from "../../pages/WorkDetail";
import Fadeup from "../Fadeup";


export default function Archiveat() {
    const wdp = workDetailPath();
    const wp = wdp + 'metapassport/';

    return (
        <div className="work-detail-wrap metapassport">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">SM Brand Marketing</h2>
                        <h4 className="project-name">
                            META
                            <br />
                            PASSPORT
                        </h4>
                        <ul className="project-team">
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                메타패스포트는 지난 2012년 8월 ‘뮤직 네이션 SM타운 (MUSIC NATION
                                SMTOWN)’ 탄생을 선포, 전 세계 30여 개국의 팬들과 시민권자들에게
                                실물로 배포했던 ‘뮤직 네이션 SM타운 패스포트’가 전환되어 현실 세계와
                                디지털 세계를 연결하고 모든 정보가 투명하게 기록되는 형태로 새롭게
                                모바일 어플리케이션으로 서비스를 제공합니다.
                                <br />
                                <br />
                                MUSIC NATION SMTOWN META-PASSPORT는 SM 그룹의 온/오프라인 이벤트마다
                                제공되는 STAMP를 비롯하여 팬클럽 가입 정보와 MUISC NATION SMTOWN의
                                시민권자로서 활동한 정보 등을 더욱 편리하게 수집하고 즐길 수
                                있는기능을 제공합니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents dark">
                <section className="section-01">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-02">
                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + 'img_02_01.png'} />
                        </Fadeup>
                    </div>
                    <div className="img-wrap">
                        <Fadeup className="img-02">
                            <img src={wp + 'img_02_02.png'} />
                        </Fadeup>
                    </div>
                    <div className="img-wrap">
                        <Fadeup className="img-03">
                            <img src={wp + 'img_02_03.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-03">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_03_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_03_03.png'} />
                    </Fadeup>
                    <Fadeup className="img-04">
                        <img src={wp + 'img_03_04.png'} />
                    </Fadeup>
                </section>

                <section className="section-04">
                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + 'img_04_01.png'} />
                        </Fadeup>
                    </div>
                    <div className="img-wrap">
                        <Fadeup className="img-02">
                            <img src={wp + 'img_04_02.png'} />
                        </Fadeup>
                    </div>
                    <div className="img-wrap img-wrap-03">
                        <Fadeup className="img-03">
                            <img src={wp + 'img_04_03.png'} />
                        </Fadeup>
                        <Fadeup className="img-04">
                            <img src={wp + 'img_04_04.png'} />
                        </Fadeup>
                        <Fadeup className="img-05">
                            <img src={wp + 'img_04_05.png'} />
                        </Fadeup>
                        <Fadeup className="img-06">
                            <img src={wp + 'img_04_06.png'} />
                        </Fadeup>
                        <Fadeup className="img-07">
                            <img src={wp + 'img_04_07.png'} />
                        </Fadeup>
                        <Fadeup className="img-08">
                            <img src={wp + 'img_04_08.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-05">
                    <div className="img-bg">
                        <img src={wp + 'img_05_bg.png'} />
                    </div>

                    <div className="marquee-container">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_05_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_06.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_07.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_08.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_09.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_10.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_11.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_12.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_05_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_06.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_07.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_08.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_09.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_10.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_11.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_05_12.png'} />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Fadeup className="img-01">
                        <img src={wp + 'img_05_mockup.png'} />
                    </Fadeup>

                    <div className="img-container">
                        <div className="img-wrap">
                            <Fadeup>
                                <img src={wp + 'img_05_13.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_05_14.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_05_15.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_05_16.png'} />
                            </Fadeup>
                        </div>
                        <div className="img-wrap">
                            <Fadeup>
                                <img src={wp + 'img_05_17.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_05_18.png'} />
                            </Fadeup>
                            <Fadeup>
                                <img src={wp + 'img_05_19.png'} />
                            </Fadeup>
                        </div>
                    </div>

                    <Fadeup className="img-logo">
                        <img src={wp + 'img_logo.png'} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
