import React, { useEffect, useRef } from 'react';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';

const Loading = (props) => {
    const loadingRef = useRef();
    const count = useMotionValue(0);
    const rounded = useTransform(count, Math.round);

    useEffect(() => {
        const animation = animate(count, 100, { duration: 3 });
        return animation.stop;
    }, []);

    return (
        <div className="Loading" ref={loadingRef}>
            <motion.div
                className="bg"
                initial={{ width: '0' }}
                animate={{ width: '100%' }}
                transition={{
                    duration: 3
                }}
                onAnimationComplete={() => {
                    loadingRef.current.style.opacity = '0';
                    props.setLoading(false);
                    setTimeout(() => {
                        if (loadingRef.current) {
                            loadingRef.current.remove();
                        }
                    }, 500);
                }}></motion.div>

            <div className="content">
                <div className="text">
                    <p>
                        If you want to work with hip developers
                        <br />
                        in Seongsu-dong,
                        <br />
                        the moment of waiting will be
                        <span className="center">a valuable experience.</span>
                    </p>

                    <small>
                        <span className="right">
                            Let's not miss the opportunity to grow inspired
                        </span>
                        <span className="center">by our creative passion </span>
                        and innovative ideas. Working with us,
                        <br />
                        who are challenging and value collaboration,
                        <br />
                        will be a rewarding journey.
                    </small>
                </div>

                <div className="progress">
                    <motion.span>{rounded}</motion.span>%
                </div>

                <div className="global-logo">
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="235"
                            height="240"
                            viewBox="0 0 235 240"
                            fill="none">
                            <path
                                d="M185.436 40.9272C176.686 28.2009 164.863 17.8391 151.04 10.7822C137.217 3.72533 121.833 0.196941 106.282 0.517342C44.5895 0.517342 -0.180664 50.7638 -0.180664 119.886C-0.180664 189.008 44.5893 239.254 106.73 239.254C122.128 239.595 137.369 236.136 151.079 229.19C164.788 222.243 176.534 212.028 185.257 199.465V234.38H234.772V5.21409H185.257L185.436 40.9272ZM116.848 47.2191C156.783 47.2191 185.794 77.7037 185.794 119.62C185.794 161.536 156.783 192.021 116.848 192.021C76.9133 192.021 49.962 162.245 49.962 119.62C49.962 76.9948 77.4506 47.2191 116.848 47.2191Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="236"
                            height="337"
                            viewBox="0 0 236 337"
                            fill="none">
                            <path
                                d="M128.883 0.517259C113.339 0.255756 97.9746 3.83894 84.1837 10.9419C70.3928 18.0449 58.6115 28.443 49.9089 41.1929V5.74569H0.393555V337.266H50.0881V199.11C58.8237 211.667 70.5698 221.882 84.2744 228.842C97.9791 235.801 113.214 239.288 128.615 238.988C190.577 238.988 235.526 188.831 235.526 119.62C235.526 50.4092 190.576 0.517259 128.883 0.517259ZM185.383 119.886C185.383 162.511 157.894 192.287 118.497 192.287C79.0992 192.287 49.4613 161.802 49.4613 119.886C49.4613 77.9695 78.4724 47.4849 118.497 47.4849C158.521 47.4849 185.383 76.9947 185.383 119.62V119.886Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="236"
                            height="337"
                            viewBox="0 0 236 337"
                            fill="none">
                            <path
                                d="M128.883 0.517259C113.339 0.255756 97.9746 3.83894 84.1837 10.9419C70.3928 18.0449 58.6115 28.443 49.9089 41.1929V5.74569H0.393555V337.266H50.0881V199.11C58.8237 211.667 70.5698 221.882 84.2744 228.842C97.9791 235.801 113.214 239.288 128.615 238.988C190.577 238.988 235.526 188.831 235.526 119.62C235.526 50.4092 190.576 0.517259 128.883 0.517259ZM185.383 119.886C185.383 162.511 157.894 192.287 118.497 192.287C79.0992 192.287 49.4613 161.802 49.4613 119.886C49.4613 77.9695 78.4724 47.4849 118.497 47.4849C158.521 47.4849 185.383 76.9947 185.383 119.62V119.886Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="220"
                            height="327"
                            viewBox="0 0 220 327"
                            fill="none">
                            <path
                                d="M210.737 97.214H146.806L49.655 193.365V0H0.0498047V326.114H49.655V223.318L155.491 326.114H219.87L97.5588 205.948L210.737 97.214Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="210"
                            height="235"
                            viewBox="0 0 210 235"
                            fill="none">
                            <path
                                d="M125.372 0.517139C110.463 -0.00927842 95.6742 3.33785 82.4783 10.2254C69.2825 17.113 58.1398 27.3008 50.1584 39.7749V5.47973H0.642578V234.38H50.1584V115.721C50.1584 71.4116 71.7372 47.396 110.866 47.396C143.548 47.396 159.397 64.4994 159.397 99.6808V234.292H209.002V90.0214C209.002 33.8376 177.752 0.517139 125.372 0.517139Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="243"
                            height="240"
                            viewBox="0 0 243 240"
                            fill="none">
                            <path
                                d="M121.592 0.517334C89.6046 0.517335 58.927 13.0936 36.3082 35.4795C13.6894 57.8655 0.982422 88.2274 0.982422 119.886C0.982422 151.544 13.6894 181.906 36.3082 204.292C58.927 226.678 89.6046 239.254 121.592 239.254C153.58 239.254 184.258 226.678 206.877 204.292C229.496 181.906 242.203 151.544 242.203 119.886C242.203 88.2274 229.496 57.8655 206.877 35.4795C184.258 13.0936 153.58 0.517335 121.592 0.517334ZM191.344 119.886C191.344 161.802 161.975 192.287 121.592 192.287C81.2099 192.287 51.7514 161.802 51.7514 119.886C51.4155 110.606 52.9721 101.355 56.3284 92.6837C59.6848 84.0126 64.772 76.0998 71.2863 69.4174C77.8007 62.735 85.6086 57.4202 94.2441 53.7901C102.88 50.16 112.165 48.2892 121.548 48.2892C130.93 48.2892 140.216 50.16 148.851 53.7901C157.487 57.4202 165.295 62.735 171.809 69.4174C178.323 76.0998 183.411 84.0126 186.767 92.6837C190.123 101.355 191.68 110.606 191.344 119.886Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                    <div>
                        <motion.svg
                            animate={{ transform: 'translateX(0)' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="164"
                            height="331"
                            viewBox="0 0 164 331"
                            fill="none">
                            <path
                                d="M164 143.384V97.214H94.3386V-0.265869H44.8227V97.214H0.0527344V143.384H44.8227V237.94C44.8227 296.25 76.5197 330.988 129.706 330.988C140.942 330.909 152.127 329.481 163.016 326.735H164V281.362L162.389 281.805C154.432 284.05 146.216 285.271 137.944 285.439C109.471 285.439 94.3386 268.247 94.3386 235.635V143.384H164Z"
                                fill="white"
                            />
                        </motion.svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
