import { useEffect, useContext, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
export default function KiaRental() {
    const variants = {
        visible: {
            opacity: 1,
            y: '0%',
            transition: {
                opacity: {
                    duration: 1.8
                },
                y: {
                    duration: 1.2
                },
                duration: 1.2,
                delay: 0.05,
                ease: 'linear'
            }
        },
        hidden: { opacity: 0, y: '50%' }
    };
    return (
        <div className="KiaRental">
            <div className="work-detail-wrap work-detail-container" data-hash="kiaRental">
                <div className="project-cover">
                    <div className="cover-img-wrap"></div>

                    <div className="cover-text-wrap dark">
                        <div className="container">
                            <h2 className="project-type">KIA Motors Corporation</h2>
                            <h4 className="project-name">KIA Rental</h4>
                            <ul className="project-team">
                                <li className="team-tag">design</li>
                                <li className="team-tag">Project Management</li>
                                <li className="team-tag">development</li>
                            </ul>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="project-desc">
                                <motion.p variants={variants}>
                                    기아자동차 렌터카 대여 고객을 위한 웹사이트와 기아렌터카
                                    영업사원을 위한 안드로이드 전용 태블릿 어플리케이션입니다.
                                    <br />
                                    <br />
                                    기아렌터카 웹사이트는 기아자동차의 장기 렌터카 견적을 손쉽게
                                    커스텀하여 받아보고, 렌터카 상담 및 계약까지 편리하게 진행할 수
                                    있는 서비스입니다. 차량 출고 후에도 차량 정보 및 여러 다이렉트
                                    서비스, 계약 기간 만료 시 차량 반납까지 고객이 차량의 모든
                                    라이프 사이클을 관리할 수 있도록 차량 관리 기능을 제공합니다.
                                    <br />
                                    <br />
                                    영업사원용 태블릿 어플리케이션에서는 영업사원들의 고객 및 견적,
                                    계약 관리 등의 전반적인 영업 활동 업무가 쉽고 편리해질 수 있도록
                                    구성되었습니다. 오프라인 뿐만 아니라 온라인 웹사이트를 통한
                                    고객의 견적을 받아보고, 상담을 통한 계약, 추후 차량 반납까지의
                                    모든 프로세스를 태블릿에서 진행할 수 있습니다.
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>

                <div className="project-contents dark">
                    <section className="cont-sec-01">
                        <div className="container">
                            <div className="cont-wrap">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-01 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_01_01_mobile.webp 296w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_01_01.webp 732w')
                                        }
                                        sizes="(max-width: 500px) 296px,
                                                    (max-width: 767px) 585px, 
                                                    732px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_01_01.webp'
                                        }
                                        alt="online"
                                    />
                                </motion.div>

                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-02 fade-up"
                                    data-delay="5">
                                    <motion.img
                                        variants={{
                                            visible: {
                                                opacity: 1,
                                                y: '0%',
                                                transition: {
                                                    opacity: {
                                                        duration: 1.8
                                                    },
                                                    y: {
                                                        duration: 1.2
                                                    },
                                                    duration: 1.2,
                                                    delay: 0.5,
                                                    ease: 'easeInOut'
                                                }
                                            },
                                            hidden: { opacity: 0, y: '50%' }
                                        }}
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_01_02.webp'
                                        }
                                        alt="X"
                                    />
                                </motion.div>

                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_01_03_mobile.webp 296w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_01_03.webp 732w')
                                        }
                                        sizes="(max-width: 500px) 296px,
                                                    (max-width: 767px) 585px, 
                                                    732px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_01_03.webp'
                                        }
                                        alt="offline"
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <section className="cont-sec-02">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.img
                                variants={variants}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_02_01_mobile.webp 154w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_02_01.webp 445w')
                                }
                                sizes="(max-width: 500px) 154px,
                                            445px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_02_01.webp'
                                }
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-02 fade-left">
                            <motion.img
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        x: '0%',
                                        transition: {
                                            opacity: {
                                                duration: 1.8
                                            },
                                            x: {
                                                duration: 0.7
                                            },
                                            duration: 0.7,
                                            delay: 0.05,
                                            ease: 'linear'
                                        }
                                    },
                                    hidden: { opacity: 0, x: '-50%' }
                                }}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_02_01_mobile.webp 296w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_02_02.webp 672w')
                                }
                                sizes="(max-width: 500px) 296px,
                                            672px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_02_02.webp'
                                }
                                alt="KIA"
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-03 fade-right">
                            <motion.img
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        x: '0%',
                                        transition: {
                                            opacity: {
                                                duration: 1.8
                                            },
                                            x: {
                                                duration: 0.7
                                            },
                                            duration: 0.7,
                                            delay: 0.5,
                                            ease: 'linear'
                                        }
                                    },
                                    hidden: { opacity: 0, x: '50%' }
                                }}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_02_03_mobile.webp 296w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_02_03.webp 663w')
                                }
                                sizes="(max-width: 500px) 296px,
                                            663px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_02_03.webp'
                                }
                                alt="Rental"
                            />
                        </motion.div>
                    </section>

                    <section className="cont-sec-03">
                        <div className="cont-wrap d-flex">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-01 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="animation-container reactive"></motion.div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-02 fade-up">
                                <motion.div
                                    variants={variants}
                                    className="animation-container reactive"></motion.div>
                            </motion.div>
                        </div>
                    </section>

                    <section className="cont-sec-04">
                        <div className="container">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-wrap fade-up">
                                <motion.div
                                    variants={variants}
                                    className="cont-img-01"></motion.div>
                            </motion.div>

                            <div className="cont-wrap d-flex stretch">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-02 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_04_02_mobile.webp 296w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_04_02.webp 732w')
                                        }
                                        sizes="(max-width: 500px) 296px,
                                                732px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_04_02.webp'
                                        }
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_04_03_mobile.webp 296w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_04_03.webp 732w')
                                        }
                                        sizes="(max-width: 500px) 296px,
                                                    732px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_04_03.webp'
                                        }
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <section className="cont-sec-05">
                        <div className="img-card-list-wrap">
                            <ul>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_01_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_01.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_01.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_02_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_02.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_02.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_03_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_03.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_03.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_04_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_04.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_04.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_05_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_05.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_05.webp'
                                        }
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="img-card-list-wrap">
                            <ul>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_06_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_06.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_06.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_07_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_07.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_07.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_08_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_08.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_08.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_09_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_09.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_09.webp'
                                        }
                                    />
                                </li>
                                <li>
                                    <img
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_10_mobile.webp 90w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_slide_10.webp 372w')
                                        }
                                        sizes="(max-width: 500px) 90px,
                                                    372px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_slide_10.webp'
                                        }
                                    />
                                </li>
                            </ul>
                        </div>

                        <div className="cont-wrap d-flex justify-between container stretch">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-01 fade-up"
                                data-delay="2.5">
                                <motion.img
                                    variants={variants}
                                    srcSet={
                                        (process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_05_04.webp 158w',
                                        process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_05_04.webp 375w')
                                    }
                                    sizes="(max-width: 500px) 158px,
                                                375px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_05_04.webp'
                                    }
                                />
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-02 fade-up"
                                data-delay="5">
                                <motion.img
                                    variants={variants}
                                    srcSet={
                                        (process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_05_03.webp 158w',
                                        process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_05_03.webp 375w')
                                    }
                                    sizes="(max-width: 500px) 158px,
                                                375px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_05_03.webp'
                                    }
                                />
                            </motion.div>
                            <div className="cont-wrap">
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-03 fade-up">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_05_01.webp 158w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_05_01.webp 375w')
                                        }
                                        sizes="(max-width: 500px) 158px,
                                                    375px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_05_01.webp'
                                        }
                                    />
                                </motion.div>
                                <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    className="cont-img-04 fade-up"
                                    data-delay="5">
                                    <motion.img
                                        variants={variants}
                                        srcSet={
                                            (process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_05_02.webp 158w',
                                            process.env.PUBLIC_URL +
                                                '/site/res/image/img_kia_section_05_02.webp 375w')
                                        }
                                        sizes="(max-width: 500px) 158px,
                                                    375px"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_05_02.webp'
                                        }
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <motion.section
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        className="cont-sec-06 fade-up">
                        <motion.div variants={variants} className="cont-wrap d-flex flex-wrap">
                            <div className="cont-img-01"></div>
                            <div className="cont-img-02"></div>
                            <div className="cont-img-03"></div>
                            <div className="cont-img-04"></div>
                        </motion.div>
                    </motion.section>

                    <section className="cont-sec-07">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="container fade-up">
                            <motion.div variants={variants} className="cont-img-01"></motion.div>
                        </motion.div>
                    </section>

                    <section className="cont-sec-08">
                        <div className="container">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    srcSet={
                                        (process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_08_01_mobile.webp 454w',
                                        process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_08_01.webp 1564w')
                                    }
                                    sizes="(max-width: 500px) 454px,
                                                1564px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_08_01.webp'
                                    }
                                />
                            </motion.div>
                        </div>
                    </section>

                    <section className="cont-sec-09">
                        <div className="container">
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className="cont-img-01 fade-up">
                                <motion.img
                                    variants={variants}
                                    srcSet={
                                        (process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_09_01_mobile.webp 296w',
                                        process.env.PUBLIC_URL +
                                            '/site/res/image/img_kia_section_09_01.webp 1130w')
                                    }
                                    sizes="(max-width: 500px) 296px,
                                                1130px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_09_01.webp'
                                    }
                                />
                            </motion.div>
                        </div>
                    </section>

                    <section className="cont-sec-10">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.img
                                variants={variants}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_01_mobile.webp 202w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_01.webp 1060w')
                                }
                                sizes="(max-width: 500px) 202px,
                                1060px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_10_01.webp'
                                }
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-02 fade-up"
                            data-delay="1">
                            <motion.img
                                variants={variants}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_02_mobile.webp 117w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_02.webp 620w')
                                }
                                sizes="(max-width: 500px) 117px,
                                620px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_10_02.webp'
                                }
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-03 fade-up">
                            <motion.img
                                variants={variants}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_03_mobile.webp 253w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_03.webp 1130w')
                                }
                                sizes="(max-width: 500px) 253w',
                                1130px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_10_03.webp'
                                }
                            />
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-04 fade-up">
                            <motion.img
                                variants={variants}
                                srcSet={
                                    (process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_04_mobile.webp 253w',
                                    process.env.PUBLIC_URL +
                                        '/site/res/image/img_kia_section_10_04.webp 1130w')
                                }
                                sizes="(max-width: 500px) 253px,
                                1130px"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/site/res/image/img_kia_section_10_04.webp'
                                }
                            />
                        </motion.div>
                    </section>

                    <motion.section
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        className="cont-sec-11 fade-up">
                        <motion.div variants={variants} className="cont-img-01"></motion.div>
                    </motion.section>

                    <section className="cont-sec-12">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="container fade-up">
                            <motion.div variants={variants} className="cont-img-01"></motion.div>
                        </motion.div>
                    </section>

                    <motion.section
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        className="cont-sec-13 fade-up">
                        <motion.div variants={variants} className="cont-wrap d-flex flex-wrap">
                            <div className="cont-img-01"></div>
                            <div className="cont-img-02"></div>
                            <div className="cont-img-03"></div>
                            <div className="cont-img-04"></div>
                        </motion.div>
                    </motion.section>

                    <section className="cont-sec-14">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className="cont-img-01 fade-up">
                            <motion.img
                                variants={variants}
                                src={process.env.PUBLIC_URL + '/site/res/image/img_kia_logo.webp'}
                                alt="KIA 렌터카"
                            />
                        </motion.div>
                    </section>
                </div>
            </div>
        </div>
    );
}
