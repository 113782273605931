import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import DelayHovering from '../components/DelayHovering';
import Typography from '../components/Typography';
import Tag from '../components/Tag';
import LinkWithTransition from '../components/LinkWithTransition';
import { GnbContext } from '../components/Pc';

function NotFound() {
    const gnbContext = useContext(GnbContext);
    const cursorRef = useRef();

    const [pos, setPos] = useState({
        x: 0,
        y: 0
    });
    const [op, setOp] = useState(0);
    const [scale, setScale] = useState(0);
    const [showSymbol, setShowSymbol] = useState(false);

    const handleMouseMove = useCallback((e) => {
        const mouseY = e.clientY;
        const mouseX = e.clientX;
        setPos({
            x: mouseX - (cursorRef.current.clientWidth/2),
            y: mouseY - (cursorRef.current.clientHeight/2),
        });
        setOp(1);
        setScale(1);
    }, []);
    const handleMouseLeav = useCallback((e) => {
        setOp(0);
        setScale(0);
    }, []);

    useEffect(() => {
        gnbContext.actions.setGnbColor("white");
        setTimeout(() => {
            setShowSymbol(true);
        },500);
    },[]);

    return (
        <div className={showSymbol ? "NotFound symbol-show" : "NotFound"}>
            <DelayHovering duration={4} delay={0} className="symbol symbol-01">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_01.webp'} />
            </DelayHovering>

            <DelayHovering duration={3} delay={0.1} className="symbol symbol-02">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_02.webp'} />
            </DelayHovering>

            <DelayHovering duration={5} delay={0.2} className="symbol symbol-03">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_03.webp'} />
            </DelayHovering>

            <DelayHovering duration={4} delay={0.3} className="symbol symbol-04">
                <img src={process.env.PUBLIC_URL + '/image/img_symbol_04.webp'} />
            </DelayHovering>

            {
                showSymbol &&
                <div className="typo-container">
                    <div
                        className="typo-wrap"
                        onMouseMove={(e) => {
                            handleMouseMove(e);
                        }}
                        onMouseLeave={(e) => {
                            handleMouseLeav();
                        }}>
                        <LinkWithTransition to="/">
                            <Typography custom={5} txt="OOPS!" rotate="none" />
                        </LinkWithTransition>
                    </div>
                    <div
                        className="typo-wrap"
                        onMouseMove={(e) => {
                            handleMouseMove(e);
                        }}
                        onMouseLeave={(e) => {
                            handleMouseLeav();
                        }}>
                        <LinkWithTransition to="/">
                            <Typography custom={8} txt="PAGE NOT FOUND" rotate="none" />
                        </LinkWithTransition>
                    </div>
                </div>
            }

            <motion.div
                className="btn-back"
                ref={cursorRef}
                style={{
                    x: pos.x,
                    y: pos.y,
                    rotate: '-9.8deg',
                    opacity: op
                }}>
                <motion.div
                    style={{
                        scale: scale
                    }}>
                    <Tag name="이전 페이지로 돌아갈까요? 🚶" />
                </motion.div>
            </motion.div>
        </div>
    );
}

export default NotFound;