import { Link, useNavigate } from "react-router-dom";
import { TransitionContext } from "./Pc";
import { useContext } from "react";

export default function LinkWithTransition(props) {
    const transitionContext = useContext(TransitionContext);

    const navigate = useNavigate();
    function delayAndGo(e) {
        e.preventDefault();
        const to = e.currentTarget.getAttribute("href");
        transitionContext.actions.setTransitionComplt(false);

        setTimeout(() => navigate(to), 500);
    }
    return (
        <Link to={props.to} onClick={delayAndGo} className={props.className ? props.className : ""}>
            {props.children}
        </Link>
    )
}