import { animate, motion } from "framer-motion"
import { useContext } from "react";
import { TransitionContext } from "./Mobile";

export default function Transition(props) {
    const transitionContext = useContext(TransitionContext);
    return (
        !transitionContext.state.complete &&
        <div className="Transition">
            <motion.div className="dim"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.3, 
                ease: "easeInOut"
            }}
            onAnimationComplete={() => {
                animate(".dim", { opacity: 0 }, { 
                    duration: 0.5,
                    onComplete: () => {
                        transitionContext.actions.setTransitionComplt(true);
                    }
                });
            }}/>
        </div>
    )
}