import { createContext, useContext, useEffect, useState } from 'react';
// import { GnbContext } from '../components/Pc';
import ContactSectionEntry from '../components/Contact/ContactSectionEntry';
import ContactSectionSummary from '../components/Contact/ContactSectionSummary';
import ContactSectionReview from '../components/Contact/ContactSectionReview';
import ContactSectionFinish from '../components/Contact/ContactSectionFinish';

export const ContactBg = createContext();

export default function ContactPage() {
    // const gnbContext = useContext(GnbContext);
    useEffect(() => {
        // gnbContext.actions.setGnbColor('white');
    }, []);
    const [bgColor, setBgColor] = useState("black");
    const bgValue = {
        state: {
            color: bgColor,
        },
        actions: {
            setBgColor
        }
    };
    return (
        <ContactBg.Provider value={bgValue}>
            <div className="Contactpage">
                <ContactSectionEntry />
                <ContactSectionSummary />
                <ContactSectionReview />
                <ContactSectionFinish />
            </div>
        </ContactBg.Provider>
    );
}
