import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import Fadeup from '../Fadeup';
import { workDetailPath } from '../../pages/WorkDetail';

export default function Archiveat() {
    const viewRef = useRef();
    const isInView = useInView(viewRef, { once: true });
    const wdp = workDetailPath();
    const wp = wdp + "archiveat/";

    return (
        <div className="work-detail-wrap archiveat">
            <div className="project-cover" ref={viewRef}>
                <div className={`cover-img-wrap ${isInView ? 'on' : ''}`}>
                    <div className="logo-wrap">
                        <img src={wp + 'img_cover_logo.png'}></img>
                    </div>
                    <div className="phone-wrap">
                        <img src={wp + 'img_cover_phone.png'} />
                    </div>
                    <div className="bg-wrap">
                        <img src={wp + 'img_cover_bg.png'} />
                    </div>
                </div>

                <div className="cover-text-wrap">
                    <div className='award-mark'>
                        <img src={wp + 'img_award_archiveat.webp'} />
                    </div>
                    <div className="container">
                        <h2 className="project-type">Public Kitchen</h2>
                        <h4 className="project-name">ARCHIVEAT</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                아카이빗은 공공의 주방이 보유한 수천만가지의 레시피를 영상과 함께
                                제공하고, 매주 큐레이션된 쿠킹박스를 정기배송 받아볼 수 있는
                                플랫폼입니다.
                                <br />
                                <br />
                                홈 화면은 영역 별로 다섯가지 이상의 템플릿을 제공하여 보다
                                다이나믹하게 콘텐츠가 보여질 수 있도록 구성하였습니다. 유저들의
                                서비스 이용 시간을 증가시킬 수 있도록 태그 및 테마 기반으로 관련
                                콘텐츠를 추천하며, 회원 및 멤버십 가입 여부에 따라 열람 가능
                                콘텐츠와 사용 기능에 제한을 두어 멤버십 가입을 유도할 수 있습니다.
                                또한 아카이빗의 다양한 콘텐츠들을 한눈에 확인하고 아카이빙 할 수
                                있도록 검색 피드와 아카이브 기능을 함께 제공합니다.
                                <br />
                                <br />
                                아카이빗은 레시피와 함께 해당 레시피에 대한 재료를 집 앞까지
                                배송해주는 쿠킹박스 정기배송 서비스를 운영합니다. 매 주 2번의 요일
                                중 원하는 요일을 지정하여, 다양한 쿠킹박스와 상품을 고르거나 배송을
                                SKIP 할 수 있습니다. 4주간의 배송 일정과 상품을 미리 지정할 수
                                있도록 주차별 커스텀 화면을 구성하여 유저들의 서비스 이해도와
                                편의성을 증대시킬 수 있도록 하였습니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents dark">
                <section className="section-01">
                    <div className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </div>

                    <Fadeup className="img-02">
                        <img src={wp + 'img_01_02.png'} />
                    </Fadeup>

                    <Fadeup className="marquee-container">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_01_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_06.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_07.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_08.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_09.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_01_03.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_06.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_07.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_08.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_01_09.png'} />
                                </li>
                            </ul>
                        </div>
                    </Fadeup>
                </section>

                <section className="section-02">
                    <Fadeup className="img-wrap">
                        <div className="img-frame">
                            <div className="img-01">
                                <img src={wp + 'img_02_01.png'} />
                            </div>
                            <div className="img-02">
                                <motion.img
                                    src={wp + 'img_02_02.png'}
                                    initial={{
                                        y: 0,
                                        transition: {
                                            duration: 0.2
                                        }
                                    }}
                                    animate={{
                                        y: '-56.5%',
                                        transition: {
                                            type: 'spring',

                                            duration: 3,
                                            repeat: Infinity,
                                            repeatType: 'reverse',
                                            repeatDelay: 1
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Fadeup>
                </section>

                <section className="section-03">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} alt="" />
                    </Fadeup>

                    <Fadeup>
                        <div className="marquee-container">
                            <div className="marquee-wrap">
                                <ul>
                                    <li>
                                        <img src={wp + 'img_03_02.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_03.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_04.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_05.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_06.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_07.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_08.png'} />
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <img src={wp + 'img_03_02.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_03.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_04.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_05.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_06.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_07.png'} />
                                    </li>
                                    <li>
                                        <img src={wp + 'img_03_08.png'} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Fadeup>
                </section>

                <section className="section-04">
                    <img src={wp + 'img_04_01.png'} />
                </section>

                <section className="section-05">
                    <Fadeup>
                        <div className="img-frame">
                            <div className="img-01">
                                <img src={wp + 'img_05_01.png'} />
                            </div>
                            <div className="vid">
                                <video
                                    src={wp + 'vid_05_01.mp4'}
                                    muted
                                    playsInline
                                    autoPlay
                                    loop></video>
                            </div>
                        </div>
                    </Fadeup>
                </section>

                <section className="section-06">
                    <Fadeup className="img-bg">
                        <img src={wp + 'img_06_bg.png'} />
                    </Fadeup>

                    <Fadeup className="img-frame-01">
                        <div className="img-01">
                            <img src={wp + 'img_06_01.png'} />
                        </div>
                        <div className="img-02">
                            <motion.img
                                src={wp + 'img_06_02.png'}
                                initial={{
                                    y: 0,
                                    transition: {
                                        duration: 0.2
                                    }
                                }}
                                animate={{
                                    y: '-81%',
                                    transition: {
                                        duration: 8,
                                        repeat: Infinity,
                                        repeatType: 'reverse',
                                        repeatDelay: 1
                                    }
                                }}
                            />
                        </div>
                    </Fadeup>
                    <Fadeup className="img-frame-02">
                        <div className="img-03">
                            <img src={wp + 'img_06_03.png'} />
                        </div>
                        <Swiper
                            className="img-slide"
                            loop={true}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            modules={[Autoplay]}>
                            <SwiperSlide>
                                <img src={wp + 'img_06_04.png'} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={wp + 'img_06_05.png'} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={wp + 'img_06_06.png'} />
                            </SwiperSlide>
                        </Swiper>
                    </Fadeup>
                </section>

                <section className="section-07">
                    <img src={wp + 'img_07_01.png'} />
                </section>

                <section className="section-08">
                    <div className="img-bg">
                        <img src={wp + 'img_08_bg.png'} />
                    </div>

                    <Fadeup className="img-frame">
                        <div className="img-01">
                            <img src={wp + 'img_08_01.png'} />
                        </div>
                        <div className="img-02">
                            <motion.img
                                src={wp + 'img_08_02.png'}
                                initial={{
                                    y: 0,
                                    transition: {
                                        duration: 0.2
                                    }
                                }}
                                animate={{
                                    y: '-63%',
                                    transition: {
                                        duration: 5,
                                        repeat: Infinity,
                                        repeatType: 'reverse',
                                        repeatDelay: 1
                                    }
                                }}
                            />
                        </div>
                    </Fadeup>
                </section>

                <section className="section-09">
                    <Fadeup className="img-wrap">
                        <img src={wp + 'img_09_01.png'} />
                        <img src={wp + 'img_09_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-wrap">
                        <img src={wp + 'img_09_03.png'} />
                        <img src={wp + 'img_09_04.png'} />
                    </Fadeup>
                    <Fadeup className="img-wrap">
                        <img src={wp + 'img_09_05.png'} />
                    </Fadeup>
                    <Fadeup className="img-wrap">
                        <img src={wp + 'img_09_06.png'} />
                        <img src={wp + 'img_09_07.png'} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
