import {
    useScroll,
    useInView,
    animate,
    useTransform,
    motion,
    useMotionValueEvent
} from 'framer-motion';
import { useEffect, useRef, useContext, useState } from 'react';
import { GnbContext } from '../../components/Pc';
export default function ContactSectionFinish() {
    const gnbContext = useContext(GnbContext);
    const viewRef = useRef();
    const textRef = useRef();
    const imgRef = useRef();
    const isInViewImg = useInView(imgRef);
    const isInView = useInView(viewRef);
    const [newClip, setClip] = useState(30);
    const isInViewText = useInView(textRef, { once: true }, { amout: 1 });
    const [action, setAction] = useState(false);

    const { scrollYProgress, scrollY } = useScroll();

    const clip = useTransform(scrollYProgress, [0.77, 0.99], [30, 0]); //전체 기준 시작점을 느리게 설정

    useMotionValueEvent(scrollYProgress, 'change', (latest) => {
        if (isInViewImg) {
            setClip(clip.current);
        } else {
            setClip(30);
        }
    });
    useEffect(() => {
        if (isInView) {
            gnbContext.actions.setGnbColor('black');
        } else {
            gnbContext.actions.setGnbColor('white');
        }
    }, [isInView]);
    useEffect(() => {
        if (isInViewText) {
            setAction(true);
        }
    }, [isInViewText]);

    return (
        <section className="ContactSectionFinish" ref={viewRef}>
            <div className={`finish-container ${isInView && 'active'}`}>
                <div className="finish-text-box" ref={textRef}>
                    <strong>
                        complete
                        <br />
                        the
                        <br />
                        new experiece
                    </strong>
                    <div className="tag instagram">
                        <span className={`${action && 'action'}`}>instagram</span>
                    </div>
                    <div className="tag youtube">
                        <span className={`${action && 'action'}`}>youtube</span>
                    </div>
                    <div className="tag contact">
                        <span className={`${action && 'action'}`}>contact</span>
                    </div>
                </div>
                <motion.div
                    className="finish-member-img"
                    style={{
                        clipPath: 'inset(0%' + newClip + '%' + newClip / 2 + '%' + 'round 15px)'
                    }}
                    transition={{
                        ease: 'easeInOut'
                    }}>
                    <img
                        src={process.env.PUBLIC_URL + '/image/img_contact_member.webp'}
                        ref={imgRef}></img>
                </motion.div>
                <div className="info-box">
                    <address>서울특별시 성동구 아차산로 126, 더리브세종타워 613호 앱노트</address>
                    <ul className="tel">
                        <li>T (+82) 2 702 5527</li>
                        <li>F (+82) 2 702 5528</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
