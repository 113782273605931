import Typography from '../Typography';

export default function MainSectionInsight(props) {
    return (
        <section className="MainSectionInsight">
            <h3>
                <small>we have</small>
                <Typography txt="Unleashing" animate={true} />
                <Typography txt="Solutions" animate={true} />
            </h3>

            <div className="card handly">
                <img src={process.env.PUBLIC_URL + '/image/img_insight_card_handly.webp'} />
            </div>
            <div className="card popee">
                <img src={process.env.PUBLIC_URL + '/image/img_insight_card_popee.webp'} />
            </div>
            <div className="card mentor">
                <img src={process.env.PUBLIC_URL + '/image/img_insight_card_mentor.webp'} />
            </div>
            <div className="card empty"></div>
            <div className="card empty"></div>
        </section>
    );
}
