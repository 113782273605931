import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default function Bentley() {
    const wdp = workDetailPath();
    const wp = wdp + 'bentley/';

    return (
        <div className="work-detail-wrap bentley">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className='award-mark'>
                        <img src={wp + 'img_award_bentley.webp'} />
                    </div>
                    <div className="container">
                        <h2 className="project-type">Bentley Seoul</h2>
                        <h4 className="project-name">Bentley MEMBERSHIP</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                벤틀리 서울 멤버십은 벤틀리 공식 오너만을 위한 온오프라인 통합
                                멤버십으로, 기존 오프라인에서 운영되던 시승 및 정비 예약, 긴급 출동
                                지원 서비스 등 차량 관리 컨시어지 서비스를 모두 앱으로 이용할 수
                                있습니다.
                                <br />
                                <br />
                                또한, 단순한 혜택 제공 수준의 여타 멤버십 프로그램과는 달리 벤틀리
                                오너들에게 어울리는 라이프스타일 경험을 제안하고 제공합니다.
                                <br />
                                <br />
                                오너들은 멤버십 가입 시 자동차를 비롯한 패션/쇼핑, 여행, 레저, 미식,
                                인테리어 등 럭셔리 라이프스타일 전반에 걸쳐 자신이 관심 있거나
                                항유하는 분야를 선택할 수 있으며, 이에 따라 맞춤형 제휴 프로그램들을
                                앱을 통해 편리하게 확인할 수 있습니다. 뿐만 아니라, 영업사원도
                                멤버십 이용 개별 고객의 성향과 활동 데이터를 지속적으로 수집하고
                                분석해 고객 관리를 더욱 체계적으로 할 수 있도록 지원합니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <Fadeup>
                        <div className="vid-01">
                            <video
                                src={wp + 'vid_01_01.mp4'}
                                playsInline
                                muted
                                autoPlay
                                loop></video>
                        </div>
                    </Fadeup>

                    <Fadeup className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </Fadeup>

                    <Fadeup className="img-02">
                        <img src={wp + 'img_01_02.png'} />
                    </Fadeup>
                </section>

                <section className="section-02">
                    <div className="marquee-container marquee-01">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_02_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_03.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_02_slide_01.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_02.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_03.png'} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-container reverse marquee-02">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + 'img_02_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_06.png'} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + 'img_02_slide_04.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_05.png'} />
                                </li>
                                <li>
                                    <img src={wp + 'img_02_slide_06.png'} />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-03">
                    <Fadeup>
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                    <Fadeup>
                        <img src={wp + 'img_03_02.png'} />
                    </Fadeup>
                    <Fadeup>
                        <img src={wp + 'img_03_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-04">
                    <Swiper
                        className="img-slide"
                        loop={true}
                        autoplay={{ delay: 2000, disableOnInteraction: false }}
                        modules={[Autoplay]}
                        slidesPerView={1.2}
                        spaceBetween={24}>
                        <SwiperSlide>
                            <img src={wp + 'img_04_01.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_04_02.png'} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={wp + 'img_04_03.png'} />
                        </SwiperSlide>
                    </Swiper>
                </section>

                <section className="section-05">
                    <Fadeup>
                        <img src={wp + "img_05_01.png"} />
                    </Fadeup>
                </section>

                <section className="section-06">
                    <img src={wp + "img_06_01.png"} />
                </section>
            </div>
        </div>
    );
}
