import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';

export default function Ekolon() {
    const wdp = workDetailPath();
    const wp = wdp + 'ekolon/';

    return (
        <div className="work-detail-wrap ekolon">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">Kolon Industries</h2>
                        <h4 className="project-name">E-KOLON MEMBERSHIP</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                eKOLON Membership은 코오롱의 20여 개 브랜드별 멤버십과 온/오프라인
                                채널을 통합시킨 멤버십 서비스입니다. 과거 운영 중인 포인트, 쿠폰,
                                혜택 등은 매장을 방문해야만 조회가 가능하였습니다. 따라서 온라인
                                채널 대비 오프라인 채널의 회원가입을 유치하고 고객 접점 채널로써
                                모바일 앱 서비스를 도입하게 되었습니다.
                                <br />
                                <br />
                                사용에 불편함이 없도록 멤버십 바코드를 단일로 제공하고 오프라인
                                쿠폰뿐만 아니라 온라인 몰에서 사용할 수 있는 쿠폰도 통합으로
                                안내하여, 고객이 해당 서비스 내에서 멤버십과 관련된 사항들을
                                편리하게 확인할 수 있도록 구성하였습니다.
                                <br />
                                <br />
                                또한 복잡했던 포스기에서의 가입 절차 대신 앱을 통한 가입을 유도하고
                                있으며, 마케팅 채널로 함께 활용할 수 있게 되었습니다. 고객은 매장을
                                방문해야만 알 수 있던 정보를 앱을 통해 온/오프라인 구분 없이 혜택을
                                받고 이벤트에 참여할 수 있게 되었습니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <Fadeup className="img-bg img-bg-01">
                        <img src={wp + 'img_01_bg_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-bg img-bg-02">
                        <img src={wp + 'img_01_bg_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-bg img-bg-03">
                        <img src={wp + 'img_01_bg_03.png'} />
                    </Fadeup>
                    <Fadeup className="img-bg img-bg-04">
                        <img src={wp + 'img_01_bg_04.png'} />
                    </Fadeup>
                    <Fadeup className="img-bg img-bg-05">
                        <img src={wp + 'img_01_bg_05.png'} />
                    </Fadeup>
                    <Fadeup className="img-bg img-bg-06">
                        <img src={wp + 'img_01_bg_06.png'} />
                    </Fadeup>

                    <Fadeup className="img-01">
                        <img src={wp + 'img_01_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-03">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                </section>
                <section className="section-04">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_04_01.png'} />
                    </Fadeup>
                </section>

                <section className="section-05">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_05_01.png'} />
                    </Fadeup>

                    <div className="img-wrap">
                        <Fadeup className="img-02">
                            <img src={wp + 'img_05_02.png'} />
                        </Fadeup>
                        <Fadeup className="img-03">
                            <img src={wp + 'img_05_03.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-06">
                    <div className="marquee-container">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + "img_06_slide_01.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_02.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_03.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_04.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_05.png"} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + "img_06_slide_01.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_02.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_03.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_04.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_05.png"} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-container reverse">
                        <div className="marquee-wrap">
                            <ul>
                                <li>
                                    <img src={wp + "img_06_slide_06.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_07.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_08.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_09.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_10.png"} />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={wp + "img_06_slide_06.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_07.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_08.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_09.png"} />
                                </li>
                                <li>
                                    <img src={wp + "img_06_slide_10.png"} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="section-07">
                    <div className="img-wrap">
                        <Fadeup>
                            <img src={wp + "img_07_01.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_07_02.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_07_03.png"} />
                        </Fadeup>
                    </div>
                    <div className="img-wrap">
                        <Fadeup>
                            <img src={wp + "img_07_04.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_07_05.png"} />
                        </Fadeup>
                        <Fadeup>
                            <img src={wp + "img_07_06.png"} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-08">
                    <Fadeup>
                        <img src={wp + "img_08_01.png"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
