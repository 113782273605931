import { useRef } from 'react';
import Typography from '../Typography';
import Tag from '../Tag';
import { useTransform, useScroll, motion } from 'framer-motion';
import LinkWithTransition from '../LinkWithTransition';

export default function MainSectionAbout(props) {
    const aboutRef = useRef();
    const aboutVideoRef = useRef();
    const videoSectionRef = useRef();

    const { scrollYProgress } = useScroll({
        target: aboutRef,
        offset: ["0vh", "200vh"]
    });
    const videoScroll = useScroll({
        target: videoSectionRef
    });
    const y = useTransform(scrollYProgress, [0, 1], ["0%", "-45%"]);
    const videoScale = useTransform(videoScroll['scrollYProgress'], [0, 1], ["137.1794871795vw", "41.0256410256vw"]);
    return (
        <>
            <section className="MainSectionAbout" ref={aboutRef}>
                <div className="contents-wrap">

                    <div className="about-bg-container">
                        <div className="about-bg">
                            <motion.img
                                style={{y: y}}
                                src={process.env.PUBLIC_URL + '/image/mobile/img_bg_main_about.webp'}
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="about-contents">
                        <div className="contents">
                            <Typography className="unleashing" txt="Unleashing" animate={true} />
                            <Typography className="innovation" txt="Innovation" animate={true} />
                            
                            {/* slogan */}
                            <div className="slogan" id="aboutSlogan">
                                <div className="marquee-wrap">
                                    <div className="slogan-text">
                                        geeweknomics
                                        <Tag class={'innovative'} name={'Innovative'} />
                                        <Tag class={'creative'} name={'Creative'} />
                                        <Tag class={'confidence'} name={'Confidence'} />
                                        <Tag class={'experience'} name={'Experience'} />
                                    </div>
                                    <div className="slogan-text">
                                        geeweknomics
                                        <Tag class={'innovative'} name={'Innovative'} />
                                        <Tag class={'creative'} name={'Creative'} />
                                        <Tag class={'confidence'} name={'Confidence'} />
                                        <Tag class={'experience'} name={'Experience'} />
                                    </div>
                                </div>
                            </div>

                            <Typography className="with-a" txt="with&nbsp;a" animate={true} />
                            <Typography className="touch-of" txt="Touch of" animate={true} />
                            <Typography className="geekiness" txt="Geekiness!" animate={true} />

                            <div className="small-msg-01">
                                <div className="en flex-column">
                                    <Typography txt="We'll" />
                                    <Typography className="right" txt="make &nbsp;&nbsp;&nbsp;&nbsp; you" delay={1} />
                                    <Typography txt="what you want" delay={2} />
                                    <Typography txt="whatever" delay={3} />
                                </div>
                                <div className="ko flex-column">
                                    <Typography txt="뭐, 원하는 것을 만들어 드리죠."  delay={1} />
                                    <Typography txt="그게 무엇이든지 간에." delay={2} />
                                </div>
                            </div>

                            <div className="small-msg-02">
                                <div className="en flex-column">
                                    <Typography className="right" txt="Show you" />
                                    <Typography txt="limitless" delay={1} />
                                    <Typography txt="possibilities" delay={2} />
                                    <Typography txt="with" delay={3} />
                                    <Typography txt="a geeky &nbsp;&nbsp;&nbsp; mind" delay={4} />
                                </div>
                                <div className="ko">
                                    <Typography txt="괴짜 같은 마인드로" delay={1} />
                                    <Typography txt="무한한 가능성을 보여드릴게요." delay={2} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="video-section" ref={videoSectionRef}>
                <div className="about-video">
                    <LinkWithTransition to="/sub">
                        <motion.div className="video-wrap" style={{height: videoScale}}>
                            <video muted playsInline loop autoPlay ref={aboutVideoRef} src={process.env.PUBLIC_URL + '/image/vid_btn_about.mp4'} type="video/mp4"> 
                                <source src={process.env.PUBLIC_URL + '/image/vid_btn_about.mp4'}></source>
                            </video>
                        </motion.div>
                        <p className="video-text">more about us</p>
                    </LinkWithTransition>
                    </div>
            </section>
        </>
    );
}
