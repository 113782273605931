import { workDetailPath } from '../../pages/WorkDetail';
import Fadeup from '../Fadeup';

export default function Stylerpick() {
    const wdp = workDetailPath();
    const wp = wdp + 'stylerpick/';

    return (
        <div className="work-detail-wrap stylerpick">
            <div className="project-cover">
                <div className={`cover-img-wrap`}>
                    <img src={wp + 'img_intro.png'} />
                </div>

                <div className="cover-text-wrap">
                    <div className="container">
                        <h2 className="project-type">THE BOOK COMPANY</h2>
                        <h4 className="project-name ko">주부생활</h4>
                        <ul className="project-team">
                            <li className="team-tag">design</li>
                            <li className="team-tag">Project Management</li>
                            <li className="team-tag">development</li>
                        </ul>
                        <Fadeup className="project-desc">
                            <p>
                                주부생활 애플리케이션은 더북컴퍼니의 콘텐츠를 통해 일반 사용자에게
                                체험단 참여와 제품 홍보 활동을 유도하는 라이프 스타일 리뷰
                                서비스입니다.
                                <br />
                                <br />
                                주부생활 서비스에서는 에디터의 콘텐츠로 독자들에게 생생한 리뷰를
                                전달하고자 하였습니다. 이를 위해 아날로그 방식의 미디어 매체를
                                모바일 환경에 맞는 디지털 미디어 콘텐츠로 손쉽게 가공하는 데에
                                초점을 맞춰 기획되었습니다. 이로써 구독자들은 ‘주부생활‘ 잡지보다
                                빠르고 편리하게 콘텐츠를 받아볼 수 있고 매체사는 광고주의 니즈에
                                맞춰 신속하게 프로모션을 진행할 수 있게 되었습니다. 더불어 체험단을
                                경험한 사용자의 2차 리뷰 콘텐츠가 생성되어 서비스가 선순환될 수
                                있도록 구성하였습니다.
                                <br />
                                <br />
                                주부생활 애플리케이션은 출시 3개월 만에 실사용자 1천 명을 바라보고
                                있는 라이프 스타일 리뷰 서비스가 되었습니다. 향후 사용자들의 리뷰
                                생활을 독려할 포인트/추천인 제도, 커뮤니티 기능 등을 기획하여 이를
                                기반으로 집중 타게팅 된 프로모션을 진행할 수 있는 플랫폼으로 발전해
                                나가고자 합니다.
                            </p>
                        </Fadeup>
                    </div>
                </div>
            </div>

            <div className="project-contents">
                <section className="section-01">
                    <div className="img-wrap">
                        <Fadeup className="img-01">
                            <img src={wp + 'img_01_01.png'} />
                        </Fadeup>
                        <div className="line"></div>
                        <Fadeup className="img-02">
                            <img src={wp + 'img_01_02.png'} />
                        </Fadeup>
                        <div className="line"></div>
                        <Fadeup className="img-03">
                            <img src={wp + 'img_01_03.png'} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-02">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_02_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_02_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_02_03.png'} />
                    </Fadeup>
                </section>

                <section className="section-03">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_03_01.png'} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + 'img_03_02.png'} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + 'img_03_03.png'} />
                    </Fadeup>
                    <Fadeup className="img-04">
                        <img src={wp + 'img_03_04.png'} />
                    </Fadeup>
                    <Fadeup className="img-05">
                        <img src={wp + 'img_03_05.png'} />
                    </Fadeup>
                </section>

                <section className="section-04">
                    <Fadeup className="img-01">
                        <img src={wp + 'img_04_01.png'} />
                    </Fadeup>
                    <div className="line"></div>
                    <Fadeup>
                        <div className="vid-01">
                            <video src={wp + 'vid_04_01.mp4'} playsInline muted autoPlay loop></video>
                        </div>
                    </Fadeup>
                    <div className="line"></div>

                    <Fadeup className="img-02">
                        <img src={wp + "img_04_02.png"} />
                    </Fadeup>

                </section>

                <section className="section-05">
                    <Fadeup className="img-01">
                        <img src={wp + "img_05_01.png"} />
                    </Fadeup>

                    <Fadeup className="img-02">
                        <img src={wp + "img_05_02.png"} />
                    </Fadeup>

                    <div className="img-wrap">
                        <Fadeup className="img-03">
                            <img src={wp + "img_05_03.png"} />
                        </Fadeup>
                        <Fadeup className="img-04" delay={2}>
                            <img src={wp + "img_05_04.png"} />
                        </Fadeup>
                        <Fadeup className="img-05" delay={3}>
                            <img src={wp + "img_05_05.png"} />
                        </Fadeup>
                    </div>
                </section>

                <section className="section-06">
                    <Fadeup className="img-01">
                        <img src={wp + "img_06_01.png"} />
                    </Fadeup>
                    <Fadeup className="img-02">
                        <img src={wp + "img_06_02.png"} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + "img_06_03.png"} />
                    </Fadeup>
                    <Fadeup className="img-04">
                        <img src={wp + "img_06_04.png"} />
                    </Fadeup>
                    <Fadeup className="img-05">
                        <img src={wp + "img_06_05.png"} />
                    </Fadeup>
                    <Fadeup className="img-06">
                        <img src={wp + "img_06_06.png"} />
                    </Fadeup>
                </section>

                <section className="section-07">
                    <Fadeup className="img-01">
                        <img src={wp + "img_07_01.png"} />
                    </Fadeup>
                    <div className="line"></div>
                    <Fadeup className="img-02">
                        <img src={wp + "img_07_02.png"} />
                    </Fadeup>
                    <Fadeup className="img-03">
                        <img src={wp + "img_07_03.png"} />
                    </Fadeup>
                </section>
            </div>
        </div>
    );
}
