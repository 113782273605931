import {motion} from "framer-motion";

export default function SolutionSectionFinish() {
    return (
        <div className="SolutionSectionFinish">
            <div className="title">VIEW MORE ABOUT</div>
            <Solution
                href="https://handly.team/product/"
                target="_blank"
                name="handly"
                img={process.env.PUBLIC_URL + '/image/img_solution_handly.webp'}></Solution>

            <Solution
                href={process.env.PUBLIC_URL + '/pdf/popee.pdf'}
                download
                right={true}
                name="popee"
                img={process.env.PUBLIC_URL + '/image/img_solution_popee.webp'}></Solution>

            <Solution
                href={process.env.PUBLIC_URL + '/site/mentor.html'}
                target="_blank"
                name="mentor"
                img={process.env.PUBLIC_URL + '/image/img_solution_mentor.webp'}></Solution>
        </div>
    );
} 

function Solution({name, children, right, img, ...props}) {

    return(
    <div className={"solution " + name}>
        {
            children
        }

        <a {...props}>
            {
            !right &&
            <div className="img">
                <motion.img src={img} />
            </div>
            }
            <motion.span>
                {name}
            </motion.span>
            {
            right &&
            <div className="img">
                <motion.img src={img} />
            </div>
            }
        </a>
    </div>
    )
}

function RollingMsg({className, msg}) {
    return (
        <motion.div className={`rolling-msg ${className ? className : ""}`} variants={{
            rest: {
                height: 0,
                transition: {
                    duration: 0.3,
                    type: "tween",
                    ease: "easeIn"
                }
            },
            hover: {
                height: 33,
                transition: {
                    duration: 0.3,
                    ease: "easeOut"
                }
            }
        }}>
            <div className="animation-container">
                <div className="msg-container">
                    <span>
                        {msg}
                    </span>
                </div>
                <div className="msg-container">
                    <span>
                        {msg}
                    </span>
                </div>
            </div>
        </motion.div>
    )
}
